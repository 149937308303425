<template>
  <div class="mtop" :class="show ? 'h5' : ''">
    <div class="box">
      <div class="router">
        <div class="logoLeft">
          <img
            src="../assets/img/logo.png"
            v-if="show"
            alt="logo"
          />
          <img
            src="../assets/img/logo.png"
            alt="logo"
            v-else
          />
        </div>


  <!-- 移动端按钮 icon -->
      <div class="phmo" v-if="show">
        <div class="ccs">
          <div class="creadw" @click="towallet" v-if="!TrxWallet">{{$t('m.glqb')}}</div>
          <div class="creadw" v-else @click="Disconnect">
            {{ TrxWallet }}
          </div>
          <div class="edit fadeInDow" v-if="qshow">
            <!-- <div class="edit-item"   @click="toslloc('/editPersonInfo')">{{$t('m.bjgrzl')}}</div> -->
            <div class="edit-item" @click="quits">{{$t('m.dc')}}</div>
          </div>
          
        </div>
        <div class="menu" v-if="show" >   
            <img src="../assets/img/zn.png" alt=""  v-if="dqyy=='zh-CN'"  class="iic" @click="tochanger"/>
            <img src="../assets/img/en.png" alt="" v-else class="iic"  @click="tochanger"/>
        
        </div>        
    
       
      </div>

      <!-- pc端按钮 icon -->
      <div class="cartent" v-else>
        <!-- 关联钱包按钮 -->
        <div class="ccs">
          <div class="creadw" @click="towallet" v-if="!TrxWallet">{{$t('m.glqb')}}</div>
          <div class="creadw" v-else @click="Disconnect">
            {{ TrxWallet }}
          </div>
          <div class="edit fadeInDow" v-if="qshow">
            <div class="edit-item" @click="quits">{{$t('m.dc')}}</div>
          </div>
        </div>
        <!-- icon -->
        <div class="imgLine">
          <img
            src="../assets/img/my-nft.png"
            alt=""
            class="iic"
            @click="toslloc('/myNFT')"
            v-if="TrxWallet && active !== '/myNFT'"
          />
          <img
            src="../assets/img/my-nft2.png"
            alt=""
            class="iic"
            v-if="TrxWallet && active == '/myNFT'"
          />
          <!-- <img
            src="../assets/img/my-wallet.png"
            alt=""
            class="iic"
          /> -->
          <!-- <img src="../assets/img/change-lang.png" alt="" class="iic" /> -->
            <img src="../assets/img/zn.png" alt=""  v-if="dqyy=='zh-CN'"   class="iic" @click="tochanger"/>
          <img src="../assets/img/en.png" alt="" v-else class="iic"  @click="tochanger">
        </div>
      </div>

      </div>    
    </div>
    <div class="editlang fadeInDow" v-if="lang">
            <div class="edit-item" :class="dqyy=='zh-CN'?'':'active'" @click="langer('en-US')">
              <img src="../assets/img/en.png" alt=""  srcset="">
              English</div>
            <div class="edit-item" :class="dqyy=='zh-CN'?'active':''" @click="langer('zh-CN')">
                <img src="../assets/img/zn.png" alt="" srcset="">
              简体中文</div>
          </div>

  </div>
</template>

<script>
import { identity} from "@/api/Public";

export default {
  data() {
    return {
      active: "/",
      show: false,
      mshow: false,
      actives: "",
      lang: false,
      qshow: false,
      lshow:false
    };
  },
  created() {
    this.active = this.$route.path;
 
  },
  computed: {
    TrxWallet() {
      if (this.$store.getters.TrxWallet) {
        return this.$store.getters.TrxWallet.replace(
          /(\w{4})\w+(\w{4})/,
          "$1...$2"
        );
      } else {
        return "";
      }
    },
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
       // 登陆类型
    type() {
      if (this.$store.getters.ltype) {
        return this.$store.getters.ltype
      } else {
        return "1";
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
      this.wallet()
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.sid": function (val) {
      this.actives = val;
    },
    "$store.getters.phone": function (val) {
      this.show = val;
    }
  },
  methods: {
    tochanger() {
      this.lang = !this.lang;
    },
    Disconnect() {
      this.qshow = !this.qshow;
      setTimeout(() => {
        this.qshow = false;
      }, 5000);
    },
    quits() { 
   this.qshow = false;
      this.$store.commit("TrxWallet", "");
      sessionStorage.removeItem("TrxWallet");
        this.$forceUpdate();
    },
    zwkf(){
      this.$notify('COMING SOON')
    },
    langer(val) {
      this.lang = false;
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    towallet(){
      // if(this.show){
        this.wallet()
      // }else{
      //  this.$router.replace({ path: 'Connect' });
      //  }
      // connect().then(res=>{
      //   console.log(res);
      //    this.$store.commit("TrxWallet", res);
      // })
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          identity().then(
            (info) => {
              clearInterval(a);
              if (info.indexOf("T") == 0) {
                this.$store.commit("TrxWallet", info);  
                      this.$forceUpdate()                   
              } else {
                this.$notify("Use the Tron address to log in to the game");
              }
            },
            () => {
              // this.$notify(e.message);
                //  this.$router.replace({ path: 'Connect' });
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
          // this.$router.replace({ path: 'Connect' });
        }
        i++;
      }, 1000);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

 
    to(url) {
      window.open(url);
    },
    toMenu() {
      this.mshow = !this.mshow;
    },
    topage(e) {
      if (e == this.active) {
        return;
      }
      this.$router.replace({ path: e });
      this.$store.commit("sid", "");
    },
    toslloc(e) {
      this.$router.push(e);
    },

  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
// pc
.mtop {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 109;
  top: 0;
  box-shadow: 0px 2px 8px 0px rgba(214, 214, 214, 0.5);
  .box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    min-height: 70px;
    .logoLeft {
      // width: 127px;
      // height: 34px;
      margin-left: 30px;
      img {
        width: 127px;
        height: 34px;
        cursor: pointer;
      }
    }
    .menu {
      width: 40px;
      height: 40px;
      margin-left: 12px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .router {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
    width: 100%;
      .txtLine {
        display: flex;
        align-items: center;
        justify-content: center;
        .btxt {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          line-height: 22px;
          margin-right: 44px;
          cursor: pointer;
          white-space: nowrap;
        }
        .active {
          opacity: 1;
          color: #FCD535;
        }
      }
      .yys {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 33px;
        .icon {
          height: 5px;
          width: 5px;
        }
        .btxt {
          margin-right: 5px;
        }
      }
      .language-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 10px;
        background: #ffffff;
        position: absolute;
        top: 100%;
        overflow: hidden;
        text-align: center;
        box-shadow: 0px 30px 40px 0px rgb(16 38 71 / 10%);
        .langItem {
          cursor: pointer;
          white-space: nowrap;
          font-size: 14px;
          background: #ffffff;
          width: 100%;

          padding: 10px;
        }
        .langItem:hover {
          background: #f8f8f8;
        }
      }
      .imgLine {
        display: flex;
        align-items: center;
        .iic {
          height: 22px;
          width: 22px;
          margin-right: 28px;
          cursor: pointer;
        }
      }
      .cartent{
        display: flex;
        align-items: center;
      .css {
        position: relative;
      }
      .creadw {
        // display: flex;
        white-space: nowrap;
        // width: 160px;
        // height: 40px;
        height: 34px;
        background: #fcd535;
        border-radius: 4px;
        font-size: 16px;
        padding: 0px 12px;
        font-family: $t-f;
        font-weight: 600;
        color: #222222;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
        // align-items: center;
        // justify-content: center;
        margin-right: 28px;
      }
      .quit {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #4c4cf3;
        color: #ffffff;
        padding: 0 20px;
        width: 160px;
        height: 42px;
        position: absolute;
        border-radius: 7px;
        font-size: 16px;
        top: 58px;
        font-family: $t-f;
        cursor: pointer;
        white-space: nowrap;
      }
      .quit:hover {
        background: #ff4747;
        color: #ffffff;
      }
      }
    }
      .edit {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        //         width: 135px;
        // height: 113px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(214, 214, 214, 0.5);
        padding: 24px 20px;
        position: absolute;
        top: 48px;
        .edit-item {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #192638;
          margin-bottom: 20px;
          cursor: pointer;
          white-space: nowrap;
        }
        .edit-item:last-child {
          margin-bottom: 0px;
        }
        .edit-item:active {
          color: #2c44f3;
        }
      }
     
  }
}
 .editlang{
         display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(214, 214, 214, 0.5);
        position: absolute;
        top: 70px;
        right: 50px;
         .edit-item {
           display: flex;
           align-items: center;
           justify-content: space-between;
              font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #192638;
          padding:10px 20px ;
          cursor: pointer;
          white-space: nowrap;
          width: calc(100% - 40px);
           img{
             width: 30px;
             height: 30px;
           }
         }
         .active{
           background: #FCD535;
           color: #ffffff;
         }
      }
// 移动
.h5 {
  position: fixed;
  
height: 106px;
.editlang{
  top: 100px;
}
.sheader{
  height: 414px;
}

  .box {
    width: calc(100% - 64px);
    background: #ffffff;
    padding: 0 32px;
    .logoLeft {
      width: 127px;
      height: auto;
      font-size: 0;
  img{
    width: 210px;
    height: 56px;
  
}
    }
      .edit{
         top: 108px;
        .edit-item{
          font-size: 26px;
          margin: 20px 0;
        }
      }

  }


  .phmo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .css {
      position: relative;
    }
    .creadw {
      // display: flex;
      white-space: nowrap;
  min-width: 160px;
height: 52px;
background: #FCD535;
border-radius: 8px;
      font-size: 28px;
      padding: 6px 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 52px;
      text-align: center;
      cursor: pointer;
      // align-items: center;
      // justify-content: center;
      margin-right: 18px;
    
    }
    .quit {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fcd535;
      color: #ffffff;
      padding: 0 20px;
      width: 220px;
      height: 52px;
      position: absolute;
      border-radius: 7px;
      font-size: 16px;
      top: 72px;
      font-family: $t-f;
      cursor: pointer;
      white-space: nowrap;
    }
    .quit:active {
      background: #ff4747;
      color: #ffffff;
    }
  }
  .yy {
    height: 44px;
    width: 44px;
    margin-right: 33px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .flexs {
    background: #ffffff;
    opacity: 0.95;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99;
    top: 0;
  }
  .txtLine {
    display: flex;
    align-items: center;
    flex: 1;
    padding-top: 120px;
    flex-direction: column;
    .btxt {
      font-size: 28px;
      font-family: $t-f;
      font-weight: 600;
      color: #0e1b40;
      line-height: 84px;
      opacity: 0.7;
      cursor: pointer;
      margin: 10px;
    }
    .active {
      opacity: 1;
    }
  }
  .imgLine {
    display: flex;
    white-space: nowrap;
    width: 264px;
    height: 48px;
    background: #f5f5f5;
    border-radius: 7px;
    font-size: 14px;
    font-family: $t-f;
    font-weight: 600;
    color: #f65080;
    line-height: 48px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

}
</style>