<template>
  <div class="main">
    <mtop @handleFiexdToolItem="handleFiexdToolItem" />
    <div class="" :class="show ? 'h5' : ''">
      <div class="header">
        <div class="mask">
          <div class="fadeInDow" v-if="active==0">
          <div class="banner-title" >PGNFT CLUB</div>
          <div class="txt">LEGENDARY DRAGON</div>
          </div>
              <div class="fadeInDow" v-if="active==1">
          <div class="banner-title" >PGNFT CLUB</div>
          <div class="txt">CIVILIZATION RABBIT</div>
          </div>
              <div class="fadeInDow" v-if="active==2">
          <div class="banner-title" >PGNFT CLUB</div>
          <div class="txt">MOVIE PANDA</div>
          </div>
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" @change="schange">
          <van-swipe-item class="sheader">
            <img src="../assets/img/activity/banner.png" alt="" class="bannner"
          /></van-swipe-item>
          <van-swipe-item class="sheader">
            <img
              src="../assets/img/activity/banner2.png"
              alt=""
              class="bannner"
          /></van-swipe-item>
           <van-swipe-item class="sheader">
            <img src="../assets/img/activity/banner3.png" alt="" class="bannner"
          /></van-swipe-item>
        </van-swipe>
      </div>

      <div class="white">
        <div class="sort" v-if="!show">
          <img
            :src="
              stype
                ? require('../assets/img/hpa.png')
                : require('../assets/img/hp.png')
            "
            alt=""
            class="ico"
            @click="tochange(false)"
          />
          <img
            :src="
              stype
                ? require('../assets/img/sp.png')
                : require('../assets/img/spa.png')
            "
            alt=""
            class="ico"
            @click="tochange(true)"
          />
        </div>
        <div :class="stype && !show ? 'list' : ''">
          <div class="white-box" :class="stype && !show ? 'w380' : ''">
            <div class="card" :class="stype && !show ? 'w380' : ''">
              <div class="box">
                <div :class="stype && !show ? 'intros' : 'intro'">
                  <div class="left fadeInLeft">
                    <img
                      src="../assets/img/activity/detail.png"
                      alt=""
                      class="img1"
                    />
                  </div>
                  <div class="right fadeInRight">
                    <h3 v-if="!show">{{ stype ?$t("m.shxls") :$t("m.shxl") +$t("m.shxls")}}</h3>
                        <h3 v-if="show">{{ $t("m.shxl") }}<br>{{ $t("m.shxls") }}</h3>
                    <div class="intro-text">
                      {{ $t("m.shxljj") }}
                    </div>
                    <div class="btns">
                      <div class="line"></div>
                      <div
                        class="check-btn"
                        @click="checkDetail('/activityDetailApprove')"
                      >
                        {{ $t("m.djck") }}
                      </div>
                      <img src="../assets/img/lr.png" alt="" class="lr" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="white-box" :class="stype && !show ? 'w380' : ''">
            <div class="card" :class="stype && !show ? 'w380' : ''">
              <div class="box">
                <div :class="stype && !show ? 'intros' : 'intro'">
                  <div class="left fadeInLeft">
                    <img
                      src="../assets/img/activity/detail2.png"
                      alt=""
                      class="img1"
                    />
                  </div>
                  <div class="right fadeInRight">
                      <h3 v-if="!show">{{ stype ?$t("m.wmxls") :$t("m.wmxl") +$t("m.wmxls")}}</h3>
                          <h3 v-if="show">{{ $t("m.wmxl") }}<br>{{ $t("m.wmxls") }}</h3>
                    <div class="intro-text">
                      {{ $t("m.wmxljj") }}
                    </div>
                    <div class="btns">
                      <div class="line"></div>
                      <div
                        class="check-btn"
                        @click="checkDetail('/activityDetail2')"
                      >
                        {{ $t("m.djck") }}
                      </div>
                      <img src="../assets/img/lr.png" alt="" class="lr" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="white-box" :class="stype && !show ? 'w380' : ''" v-if="acshow">
            <div class="card" :class="stype && !show ? 'w380' : ''">
              <div class="box">
                <div :class="stype && !show ? 'intros' : 'intro'">
                  <div class="left fadeInLeft">
                    <img
                      src="../assets/img/activity/detail3.png"
                      alt=""
                      class="img1"
                    />
                  </div>
                  <div class="right fadeInRight">
                      <h3 v-if="!show">{{ stype?$t("m.mpxls") :$t("m.mpxl") +$t("m.mpxls")}}</h3>
                      <h3 v-if="show">{{ $t("m.mpxl") }}<br>{{ $t("m.mpxls") }}</h3>
                    <div class="intro-text">
                      {{ $t("m.mpxljj") }}
                    </div>
                    <div class="btns">
                      <div class="line"></div>
                      <div
                        class="check-btn"
                        @click="checkDetail('/activityDetailWhite')"
                      >
                        {{ $t("m.djck") }}
                      </div>
                      <img src="../assets/img/lr.png" alt="" class="lr" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="Mintshow" round :close-on-click-overlay="sqloading">
      <div class="popup" :class="show ? 'popuph5' : ''">
        <div class="title"></div>
        <div class="boxs">
          <div class="item fadeInUp" v-for="(item, i) in list" :key="i">
            <!-- <img :src="item" alt="" class="sbg" v-if="item" /> -->
            <div class="mbg" v-if="!sqloading">
              <video
                :src="item.image"
                class="img"
                v-if="item.isVideo"
                autoplay
                loop
              ></video>
              <img :src="item.image" alt="" v-else class="img" />
            </div>
            <div class="bh" v-if="!sqloading">{{ item.name }}</div>
          </div>
          <div class="mbg" v-if="sqloading">
            <img src="../assets/img/mbg.png" alt="" class="sbg" />
            <div class="zzz">Mint...</div>
          </div>
        </div>
        <div class="bottom">
          <van-button
            :loading="sqloading"
            type="primary"
            @click="close"
            class="btn"
            loading-text="Mint..."
          >
            Close</van-button
          >
        </div>
      </div>
    </van-popup>

    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import {
  connectTron, 
  ConstructMerkleTree4
} from "@/api/Public_approve";
export default {
  data() {
    return {
      show: false,
      timer: false,
      screenWidth: document.body.clientWidth,
      sqloading: false,
      buynum: 1,
      Cbuy: true,
      Mintshow: false,
      Cmint: true,
      list: {},
      mode: 1,
      mlist: [],
      price: 0,
      Maxnum: 0,
      Max: 1,
      mp4: true,
      stype: true,
       acshow:true,
       active:0
    };
  },
  components: {
    mtop,
    mbottom,
  },
  computed: {
    userWallet() {
      if (this.$store.getters.userWallet) {
        return this.$store.getters.userWallet.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
    // this.getproe()
  },
  created() {
    this.getModer();        
  },
  computed:{
     // 登陆类型
    type() {
      if (this.$store.getters.ltype) {
        return this.$store.getters.ltype
      } else {
        return "1";
      }
    },
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$store.getters.sid": function (val) {
      this.handleFiexdToolItem(val);
    },
    "$store.getters.userWallet": function () {
      this.getModer();
    },

    deep: true,
    immediate: true,
  },
  methods: {
    schange(e){
      this.active=e
    },
      getproe(){
        setTimeout(() => {
      ConstructMerkleTree4(this.$store.getters.userWallet,this.type).then(
        (res)=>{
            this.acshow=res
            // console.log(res);
        }
      )
      
          
        }, 1000);
    },
    tochange(e) {
      this.stype = !e;
    },
    checkDetail(e) {
      this.$router.push(e);
    },
    tourl(e) {
      window.open(e);
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                // console.log(info[0]);
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$notify("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$notify(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    getModer() {},

    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    topage(e) {
      this.$router.replace({ path: e });
    },
    toCread() {},
    up() {
      if (this.sqloading || !this.Cmint) {
        return;
      }
      this.buynum =
        this.buynum <= this.Max ? this.Max : Number(this.buynum) + 1;
    },
    dow() {
      if (this.sqloading || !this.Cmint) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },

    toMint() {
      //  this.list.length = this.buynum;

      // this.Mintshow=true
      // this.getList()
      // return
      if (this.buynum <= 0 || this.sqloading) {
        return;
      }
      this.sqloading = true;
      this.list = {};
      let userWallet = this.$store.getters.userWallet;
      if (this.mode < 4) {
        this.$get({
          url: "mint/mintlist",
          data: {
            type:
              this.mode == 1
                ? "0"
                : this.mode == 2
                ? "2"
                : this.mode == 3
                ? "1"
                : "0",
          },
          callback: (res) => {
            this.$nextTick(function () {
              if (res.data) {
                this.mlist = res.data;
                ConstructMerkleTree(
                  userWallet,
                  this.mlist,
                  this.buynum,
                  this.mode
                )
                  .then((res) => {
                    // console.log(res);
                    if (res) {
                      this.getList();
                      this.Mintshow = true;
                      this.Cmint = true;
                    } else {
                      this.Cmint = false;
                      this.sqloading = false;
                    }
                  })
                  .catch(() => {
                    this.sqloading = false;
                  });
              }
            });
          },
        });
      } else if (this.mode == 4) {
        ConstructMerkleTree(userWallet, [], this.buynum, this.mode)
          .then((res) => {
            // console.log(res);
            if (res) {
              this.getList();
              this.Mintshow = true;
              this.Cmint = true;
            } else {
              this.Cmint = false;
              this.sqloading = false;
            }
          })
          .catch(() => {
            this.sqloading = false;
          });
      }
    },
    getList() {
      // this.list={}
      walletOfOwner(this.$store.getters.userWallet).then((res) => {
        // console.log("res",res);
        var list = res.slice(-this.buynum);
        // console.log("list",list);
        // this.list={}
        list.forEach((el) => {
          // console.log('el',el);
          this.getUrl(el);
        });
      });
    },

    getUrl(e) {
      tokenURI(e).then((res) => {
        // console.log("url", res);
        // let regs = new RegExp("https://ipfs.io/", "g");
        // var urls = res.replace(regs, "https://www.cupidlovemetaverse.com/");
        this.$axios({
          method: "GET",
          url: res,
        }).then((response) => {
          // console.log(response.data.image);
          // let reg = new RegExp("ipfs://", "g");
          // var url = response.data.image.replace(
          //   reg,
          //   "https://www.cupidlovemetaverse.com/ipfs/"
          // );
          this.list[e] = response.data;
          // console.log("list", this.list);
          this.sqloading = false;
          this.$forceUpdate();
        });
      });
    },
    close() {
      gettotal().then((res) => {
        if (res >= 666) {
          this.Cbuy = false;
        }
        this.Maxnum = res;
      });
      this.Mintshow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
$t-sf:'MiSans';

.main {
  background: #ffffff;
  .header {
    position: relative;
        font-size: 0;
    .mask {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      z-index: 9;
      background: rgb(0 0 0 / 45%);
      .banner-title {
        font-size: 48px;
        font-family: $t-sf;
        font-weight: bold;
        color: #ffffff;
        line-height: 70px;
        letter-spacing: 4px;
      }
      .txt {
        font-size: 28px;
        font-family: $t-sf;
        font-weight: 500;
        color: #ffffff;
        line-height: 38px;
        letter-spacing: 8px;
      }
    }
  }
  .sheader {
    // background: url("../assets/img/activity/banner.png") no-repeat center;
    // background-size: cover;
    // min-height: 600px;
    width: 100%;
    .bannner {
      width: 100%;
      height: auto;
    }
    .banner-title {
      font-size: 60px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-top: 167px;
      //   margin-left: 320px;
    }
    p {
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .sheader2 {
    background: url("../assets/img/activity/banner2.png") no-repeat center;
    background-size: cover;
    min-height: 600px;
    width: 100%;
    padding-top: 70px;
  }
  .sbtn {
    background: #171719;
    // background: #000000;
    height: 53px;
    width: 100%;
  }
  .gray {
    background: #c8c8c8;
    padding: 50px 0;
    min-height: 746px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tt4 {
      font-size: 30px;
      font-family: $t-f;
      font-weight: 600;
      color: #000000;
      line-height: 42px;
    }
    .img2 {
      // width: 100%;
    }
  }

  .white {
    // min-height: calc(100vh - 70px);
    // display: flex;
    // align-items: center;
    padding: 116px 0;
    background: #f7f8fb;
    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 1200px;
      margin: 0 auto;
    }
    .sort {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 1200px;
      margin: 0 auto;
      .ico {
        height: 16px;
        width: 16px;
        margin: 0 15px;
        cursor: pointer;
      }
    }
    .white-box {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 30px 0;
    }
    .card {
      //   width: 1100px;
      width: 1200px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;

      .box {
        .intro {
          display: flex;
          justify-content: space-between;
        }
        .left {
          .img1 {
            width: 380px;
            height: 250px;
          }
        }
        .right {
          justify-content: flex-start;
          text-align: left;
          height: 210px;
          background: #ffffff;
          padding: 20px 30px;
          h3 {
            font-size: 18px;
            font-family: $t-sf;
            font-weight: 600;
            color: #000000;
            line-height: 24px;
            text-align: left;
          }
          .intro-text {
            font-size: 14px;
            font-family: MiSans-Normal, MiSans;
            font-weight: 400;
            color: #000000;
            line-height: 18px;
          }
          .btns {
            display: flex;
            align-items: center;
            margin-top: 40px;
            .line {
              width: 63px;
              height: 1px;
              background: #000000;
              margin-right: 20px;
            }
            .check-btn {
              font-size: 14px;
              font-family: $t-sf;
              font-weight: 400;
              color: #000000;
              line-height: 19px;
              cursor: pointer;
            }
            .lr {
              width: 5px;
              height: 8px;
            }
          }
        }
        .right:hover {
          background: #f9b800;
        }
        .intros {
          flex-direction: column;
          .right {
            padding: 40px 20px;
            height: 240px;
            // height: auto;
            h3 {
              margin: 0 0 20px;
            }
          }
        }
      }
    }
    .w380 {
      width: 380px;
      margin: 0 15px;
    }
  }

  .hzhb {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tt {
      font-size: 38px;
      font-family: $t-f;
      font-weight: bold;
    }
    img {
      width: 1112px;
      height: 351px;
      margin: 71px auto;
    }
  }
  .popup {
    // width: 1200px;
    background: #171719;
    border: 6px solid #78c2fa;
    border-radius: 16px;
    .title {
      height: 64px;
      font-size: 28px;
      font-style: italic;
      font-weight: 600;
      color: #ffffff;
      line-height: 64px;
    }
    .boxs {
      padding: 20px 19px 30px;
      // height: 600px;
      overflow: auto;
      display: flex;
      flex-flow: wrap;

      .item {
        height: 270px;
        width: 270px;
        margin: 10px;
        position: relative;
      }
      .sbg {
        height: 264px;
        width: 264px;
        border-radius: 10px;
        cursor: pointer;
      }

      .mbg {
        height: 264px;
        width: 264px;
        border-radius: 20px;
        border: 1px solid #a5a6a7;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          height: 250px;
          width: 250px;
          border-radius: 20px;
        }
      }
      .zzz {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        font-weight: 500;
        font-family: $t-f;
        color: #000000;
        line-height: 34px;
        white-space: nowrap;
      }
      .bh {
        min-width: 100px;
        font-size: 26px;
        font-family: $t-f;
        margin-top: 10px;
        font-weight: 600;
        color: #ffffff;
        white-space: nowrap;
      }
    }
    .bottom {
      // background: #ffffff;
      height: 131px;
      .btn {
        width: 235px;
        height: 56px;
        background: #4c4cf3;
        border-radius: 8px;
        box-shadow: 0px 10px 18px 0px rgb(23 23 25);
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        margin-top: 38px;
        cursor: pointer;
        border: none;
      }
    }
  }
  .popuph5 {
    width: calc(100vw - 100px);
    .boxs {
      justify-content: center;
    }
  }

  .h5 {
    .banner-title {
      width: 80%;
      padding: 144px 20px 0;
      font-size: 40px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #ffffff;
      margin: 0 auto;
      line-height: 60px;
    }
    .header {
      width: 100%;
      .left {
        // background: url('../assets/imgh/stop.png') no-repeat;
        background-size: 253px 267px;
        height: 267px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 60px;
        .tt1 {
          font-size: 50px;
          font-family: $t-f;
          font-weight: bold;
          color: #4458fe;
        }
        .tt2 {
          font-size: 33px;
          font-family: $t-f;
          font-weight: bold;
          color: #333333;
        }
      }
      .right {
        img {
          width: 100%;
        }
      }
    }

    .cardh {
      .left {
        margin: 31px 61px;
        .tt1 {
          font-size: 42px;
          font-family: $t-f;
          font-weight: bold;
          color: #4458fe;
        }
        .tt2 {
          font-size: 42px;
          font-family: $t-f;
          font-weight: bold;
          color: #333333;
          margin-top: 17px;
          margin-bottom: 30px;
        }
        .tt3 {
          font-size: 25px;
          font-family: $t-f;
          font-weight: 400;
          color: #333333;
        }
      }
      .right {
        margin-top: 31px;
        img {
          width: 100%;
        }
      }
    }
    .cs {
      background: linear-gradient(38deg, #faf1ec 0%, #eeeef9 52%);
      margin-top: -34px;
      padding-top: 34px;
    }
    .shzhb {
      width: 100%;
      margin: 50px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .tt {
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
      }
      img {
        width: 698px;
        height: 219px;
        margin: 71px auto;
      }
    }
  }
  .h5 {
    .sheader {
      .bannner {
        width: auto;
        height: 546px;
      }
      .herder {
        width: 100%;
        .left {
          width: calc(100% - 40px);
          margin: 20px;
          .box {
            width: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px;
            .stip {
              font-size: 23px;
              margin-bottom: 10px;
              margin-right: 0;
            }
            .mint {
              margin-left: 0;
              .input {
                margin-right: 30px;
              }
            }
            .creadw {
              margin-left: 0;
            }
          }
        }
      }
    }
    .white,
    .our-idea,
    .begin {
      h3 {
        font-size: 26px;
      }
      .card {
        flex-direction: column;
        padding: 0;
        width: 100%;
        .box {
          width: 100%;
          flex-direction: column;
          .intro {
            flex-direction: column;
            margin: 0 0 40px;
          }
          .left {
            margin: 0;
            font-size: 0;
            .img1 {
              width: 100%;
              height: auto;
              border-radius: 26px 26px 0 0;
            }
          }
          .right {
            justify-content: center;
            display: flex;
            flex-direction: column;
            height: auto;
            background: transparent;
            h3 {
              text-align: left;
              margin: 20px 0;
              font-size: 28px;
              font-family: $t-sf;
              font-weight: 600;
              color: #000000;
              line-height: 38px;
            }
            .intro-text {
              font-size: 24px;
              font-family: MiSans-Normal, MiSans;
              font-weight: 400;
              color: #000000;
              line-height: 36px;
            }
            .check-btn {
              margin: 0px 10px;
              min-width: 112px;
              height: 38px;
              font-size: 28px;
              font-family: $t-sf;
              font-weight: 400;
              color: #7f7f7f;
              line-height: 38px;
            }
            .lr {
              width: 10px;
              height: 16px;
            }
          }
          .intros {
            flex-direction: column;
          }
        }
      }
    }
    .white .white-box {
      width: auto;
      background: #ffffff;
      border-radius: 26px;
      margin: 20px 40px;
      padding: 0;
    }
  }
}
</style>