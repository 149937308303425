export const m = {
  // 顶部
  sy:'首页',
  hd:'活动',
  ts:'探索',
  phb:'排行榜',
  lc:'龙场',
  glqb:'关联钱包',
  bjgrzl:'编辑个人资料',
  dc:'登出',
  wdnft:'我的NFT',
  // 首页
  wsmxzwm:'为什么选择我们',
  title1:'多链艺术市场赋能板块',
  info1:'   FistNFT为艺术家提供了一个以 NFT形式创作独特、真实的数字收藏品的场所。实现多链衔接布局，支持ETH/BSC/Polygon等多链NFT资产。',
  title2:'支持主流去中心化存储方案',
  info2:'FistNFT上的每一个数字创作都由创作者进行数字签名，并通过主流去中心化区块链技术永久记录和验证，所有资产数据安全有保障，确保数字资产创作的稀缺性和独特性。',
  title3:'独创NFT经济赋能板块',
  info3:'FistNFT重视每一个NFT项目的生态价值建设，平台独创NFT经济赋能板块，支持优质NFT项目扩展生态，不断增加持有者的权益',
  ptln:'平台理念',
  szcpylwl:'数字藏品引领未来',
  lmkcy:'0门槛参与NFT',
  bhczzqy:'保护创作者权益',
  jjjzfn:'经济价值赋能NFT',
  kstsb:'开始探索吧',
  sc:'市场',
// 底部
  dysstx:'订阅时事通讯,不错过最新功能版本更新、NFT空投',
  ndyxdz:'您的邮箱地址',
  dy:'订阅',
  qbfl:'全部分类',
  ysp:'艺术品',
  scp:'收藏品',
  xnsj:'虚拟世界',
  shiy:'实用',
  yyue:'音乐',
  ty:'体育',
  ym:'域名',
  cj:'创建',
  tj:'推荐',
  phb:'排行榜',
  gy:'关于',
  gywm:'关于我们',
  bzzx:'帮助中心',
  bqsy:'版权所有',
  yssm:'隐私声明',
  fwtk:'服务条款',
// 活动
  shxl:'PGNFT CLUB系列一：',
  shxls:'LEGENDARY DRAGON 神话系列',
  shxljj:'活动介绍：来源于中国古代各类神话传说，从山海经到聊斋志异，从四书五经到史记。以龙这一中国代表性神话动物为主体，附以具象化的神话元素和道具，全方位立体呈现LEGENDARY DRAGON。',
  djck:'点击查看',
  wmxl:'PGNFT CLUB系列二: ',
  wmxls:'CIVILIZATION RABBIT 文明系列',
  wmxljj:'活动介绍：来源于中国文明，从有形的譬如名山大川，历史遗迹，发明创造等；到无形的譬如风土人情，文化习俗，思想理念等。以兔子这一平易近人极具亲和力的形象为主体，彰显泱泱华夏大气平和的形象气度，走向世界。',
  mpxl:'PGNFT CLUB 系列三：',
  mpxls:'MOVIE PANDA',
  mpxljj:'活动介绍：来源于中国从古至今的各类艺术影视文化，从戏曲百花苑，到电影电视；从民间娱乐，到大众综艺，跨度之大之久。以国宝熊猫为载体，以其广泛的知名度和接受度，附加形式多样的娱乐性，实现破圈。',
  djzz:'点击铸造',
  zzz:'铸造中...',
  fxgz:'发行规则',
  fxmx:'发行数量：20000枚（sss级200枚，ss级2800枚，s+级7000枚，s-级10000枚）',
  gfsm:'面向持有BSC链上的PGNFT的用户，官方无预留无白名单，全部由用户自己实时铸造产生。用户可在活动铸造页面，通过支付1 PGNFT直接铸造，用户所支付的PGNFT将全部销毁。铸造完成后可在我的收藏品中查看。',
  zzxz:'单次最小铸造数量1个，最大铸造数量50个；',
  fxmx2:'发行数量：200000枚（无等级分别）',  
  gfsm2:'面向全部用户，全部由用户实时铸造产生。用户可在活动铸造页面以低成本进行铸造，铸造完成后可在我的收藏品中查看。 ',
  fxmx3:'发行数量：70000枚（无等级分别）',
  gfsm3:'发行规则：面向持有FilmNFT影视卡的用户，官方无预留无白名单，全部由用户自己铸造产生。仅限通过销毁影视卡获得白名单的用户，获得铸造白名单的用户可在活动页面进行1:1免费铸造，铸造完成后可在我的收藏品中查看。',
  gxnhd:'恭喜你获得',
  qr:'确认',
    // 我的NFT
  zwwtx:'暂未填写简介',
  scps:'收藏品',
  jg:'结果',
  // 个人信息
  bjgrxx:'编辑个人信息',
  tx:'头像',
  txzc:'支持PNG、JPG、GIF，推荐尺寸400 × 400px，大小在1M以内',
  scwj:'上传文件',
  zyfmt:'主页封面图',
  zyfmtxx:'支持PNG, JPEG, JPG，推荐尺寸1400 × 400px，大小在3M以内',
  yhm:'用户名',
  yhmxx:'4-60个字符，支持中英文、数字、下划线和减号',
  jj:'简介',
  tjlj:'添加链接',
  ndwz:'您的网站',
  tj:'提交',
  tjcg:'提交成功',
  tjsb:'提交失败 ',
  sccg:'上传成功',
  scsb:'上传失败',
  scwjgd:'上传文件过大',
  ljqb:'连接您的钱包',





  jqqd:'暂未开始',
  az:'安卓',
  fzcg: '复制成功',
  fzsb: '复制失败',

  xhmc:'销毁Film NFT',
  dqqbdz:'当前钱包地址',
  qsrnft:'请输入销毁NFT编号',
  nftbh:'NFT编号',
  qdxh:'确定销毁',
  ljbcqb:'链接波场钱包',
  sm:'用户操作注意事项',
  sminfo:'需要使用波场钱包连接，输入您要销毁的Film NFT数量，确认后我们会将您的Film NFT转入黑洞地址进行销毁，后续您可在fstnft.com活动参与PGNFT CLUB系列三活动。',
  qsrzq:'请输入您拥有的正确的NFT编号',
  qhcg:'销毁成功！',
  bj:'编辑',
  zylj:'主页链接',
  xlwb:'新浪微博',
  hdjs:'铸造已结束',

  zly:'致力于让每一个人拥有',
  bcy:'并参与到NFT世界中',
  bdpt:'技术布道者、开发者、创造者和创业者的交流平台',
  wfhfn:'为FIST NFT开发提供孵化和赋能',
  zdkzzsl:'最大可铸造的数量：',
  xhsj:'波场FilmNFT销毁时间：6月29日16点到7月14日16点  为期15天',
  kqsj:'开启1:1铸造时间：7月16日16点',
  cyxh:'参与销毁的NFT处理方式：将打入波场链黑洞地址，全部销毁',
  in1:'1.用户在进行销毁时，需连接正确的钱包地址。',
  
  in2:'2.用户在销毁Film NFT时，需准确输入钱包地址中持有的Film NFT的编号。',
  
  in3:'3.波场FilmNFT销毁时间：6月30日16:00到7月28日16:00。为期28天，超过28天后，不可再进行销毁操作。',
  
  in4:'4.每7天释放一批白名单铸造地址，释放后方可开启铸造。首次铸造白名单地址公布并开启铸造时间：7月6日16:00',
  
  in5:'5.用户需保留好销毁Film NFT使用的波场钱包私钥。后续在BSC链上铸造PGNFT CLUB系列三MOVIE PANDA NFT时，需使用销毁Film NFT连接的波场钱包私钥导入BSC链，并进行铸造。',
  
  in6:'6.参与销毁的NFT处理方式：将打入波场链黑洞地址，全部销毁。',
  
  in7:'注：所有在波场链进行过销毁Film NFT操作的地址，链上会收集对应BSC地址并列为白名单地址，白名单地址会以谷歌文档的形式公示，用户可自行用波场钱包私钥导入BSC链查看自己对应的BSC地址并在谷歌文档进行对照。每7天公布一次白名单地址。',
  sthb:'生态伙伴'
  
}