<template>
  <div id="app">
    <transition name="slide-fade" mode="out-in">
      <router-view v-if="shows" />
      <div v-else class="jzzs">
        <div>
          <van-loading type="spinner" color="#000" />
        </div>
        <div>{{ tet }}</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
      show:false,
      timer:false,
      screenWidth: document.body.clientWidth
    };
  },
  data() {
    return {
      shows: true,
      tet: "loading ...",
    };
  },

 beforeCreate() {   
  },
   mounted() {  
      window.onresize = () => {
        return (() => {          
            window.screenWidth = document.body.clientWidth
            this.screenWidth = window.screenWidth  
            // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
            this.show= this.screenWidth>800?false:true
            this.$store.commit("phone", this.show);
        })()
    }    
  },
  
  created() {
    this.screenWidth=document.body.clientWidth
    this.show= this.screenWidth>800?false:true
    this.$store.commit("phone", this.show);
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
    cread() {
  
    },
  },
};
</script>

<style lang="scss">
a{
  display: none;
}
#app {
  background: #f8f8f8 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.van-toast {
  font-size: 16px !important;
  line-height: 36px !important;
  // background-color:#ff9ab5 !important;
}
#app {
  font-family: DINPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  width: 100%;
    overflow: hidden;
}
// @font-face {
//   font-family: Dinblack;
//   src: url("~@/assets/font/DIN-BLACK.otf");
// }
@font-face {
  font-family: DINPro;
  src: url("~@/assets/font/DINPro-Bold.otf");
}
@font-face {
  font-family: MiSans;
  src: url("~@/assets/font/Shree714.ttf");
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.van-popup__close-icon{
  font-size: 36px !important;
}
.van-notify{
  font-size: 36px !important;
  line-height: 50px !important;
}
// .van-notify--danger{
//   background: rgb(61 60 60 / 82%) !important;
// }
.van-popup--top{
    //   top: 50% !important;
    // left: 50% !important;
    // width: 80% !important;
    // transform: translate3d(-50%,-50%,0);
    // padding-left: 150px !important;
    // border-radius: 10px !important;
    // min-height: 100px !important;
   
}
.van-popup--top::before{
  content: "";
  //  background: url('../src/assets/img/nft.png') no-repeat;
   background-size: contain;
    height: 100px;
    width: 202px;
    position: absolute;
    z-index: 2005;
    left: 0;
    bottom: 0;
}
.jzzs {
  height: 100vh;
}
.jzzs div {
  padding-top: 20%;
  font-size: 23px;
  color: #2c3e50;
}
@keyframes fadeIn { from { opacity: 0; }
  to { opacity: 1; } }
.fadeIn { animation-duration: .8s; animation-name: fadeIn; }


@keyframes fadeInUp { from { opacity: 0; transform: translate3d(0, 40px, 0); }
  to { opacity: 1;transform: translate3d(0, 0, 0); } }
.fadeInUp { animation-duration: .8s; animation-name: fadeInUp; }

@keyframes fadeInDow { from { opacity: 0; transform: translate3d(0, -20px, 0); }
  to { opacity: 1;  transform: translate3d(0, 0, 0); } }
.fadeInDow { animation-duration: .8s; animation-name: fadeInDow; }



@keyframes fadeInRight { from { opacity: 0;  transform: translate3d(40px, 0, 0); }
  to { opacity: 1;  transform: translate3d(0, 0, 0); } }
.fadeInRight { animation-duration: .8s; animation-name: fadeInRight; }


@keyframes fadeInLeft { from { opacity: 0;  transform: translate3d(-40px, 0, 0); }
  to { opacity: 1; transform: translate3d(0, 0, 0); } }
.fadeInLeft { animation-duration: .8s; animation-name: fadeInLeft; }

</style>
