import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userWallet: "" || sessionStorage.getItem("userWallet"),
    TrxWallet:''||sessionStorage.getItem('TrxWallet') ,
    phone: "" || sessionStorage.getItem("phone"),
    sid: "" || sessionStorage.getItem("sid"),
    intID: "" || sessionStorage.getItem("intID"),
    myTrx: "" || localStorage.getItem("myTrx"),
    myFst: "" || localStorage.getItem("myFst"),
    myDmcc:"" || localStorage.getItem("myDmcc"),
    ltype:''|| sessionStorage.getItem("ltype"),
    language: localStorage.getItem('yy') || "zh-CN",
     theame:localStorage.getItem('theame') || '0',
  
  },
  mutations: {
    userWallet(state, val) {
      state.userWallet = val;
      sessionStorage.setItem("userWallet", val);
    },
    language(state,val){
      state.language = val
      localStorage.setItem('yy', val)
    },
    phone(state, val) {
      state.phone = val;
      sessionStorage.setItem("phone", val);
    },
    sid(state, val) {
      state.sid = val;
      sessionStorage.setItem("sid", val);
    },
    intID(state, val) {
      state.intID = val;
      sessionStorage.setItem("intID", val);
    },
    myTrx(state, val) {
      state.myTrx = val;
      localStorage.setItem("myTrx", val);
    },
    myFst(state, val) {
      state.myFst = val;
      localStorage.setItem("myFst", val);
    },
    myDmcc(state, val) {
      state.myDmcc = val;
      localStorage.setItem("myDmcc", val);
    },
    ltype(state, val) {
      state.ltype = val;
      sessionStorage.setItem("ltype", val);
    },
      theame(state, val) {
      state.theame = val;
      localStorage.setItem("theame", val);
    },
    TrxWallet(state, val) {
      Vue.set(state,'TrxWallet',val)
      sessionStorage.setItem("TrxWallet", val);
    },
  },
  actions: {},
  modules: {},
  getters: {
    userWallet: (state) => state.userWallet,
    phone: (state) => state.phone,
    sid: (state) => state.sid,
    intID: (state) => state.intID,
    myTrx: (state) => state.myTrx,
    myFst: (state) => state.myFst,
    myDmcc: (state) => state.myDmcc,
    ltype: (state) => state.ltype,
    language: (state) => state.language,
     theame: (state)=>state.theame,
     TrxWallet:(state)=>state.TrxWallet
  },
});
