<template>
  <div class="main">
    <mtop  />

   
    <div class="card" :class="show?'':'web'">
 <div class="title">{{$t('m.xhmc')}}</div>
 <div class="box" v-if="TrxWallet">
    
    <div class="item fadeInUp" >
        <div class="txt">{{$t('m.dqqbdz')}}</div>
        <div class="my">{{TrxWallet}}</div>
    </div>
    <div class="item fadeInUp">
        <div class="txt">{{$t('m.qsrnft')}}:</div>
        <div class="input">
            <van-field v-model="bhnum" type="digit" class="sinpt" :label-width='0'  :maxlength="7"  :placeholder="$t('m.nftbh')" :readonly='xhloading'/>
            <!-- <input type="number" :maxlength="7" v-model="bhnum" :placeholder="$t('m.nftbh')" :readonly='xhloading'> -->
        </div>
    </div>
    <div class="bottom"> 
         <van-button
            :loading="xhloading"
            type="primary"
            class="btn"
            disabled
          >           
           {{$t('m.qdxh')}}</van-button
          >  </div>

 </div>
   <div class="wallet fadeInUp" @click="wallet" v-else>
          <div class="left">{{$t('m.ljbcqb')}}</div>
          <div class="right">
              <img src="../assets/img/my-nft/metamask.png" alt="">
          </div>
      </div>
    </div>
    <div class="tips" :class="show?'':'webt'">
        <div class="txt">{{$t('m.sm')}}</div>
        <div class="sinfo">
            <div class="p">
            {{$t('m.in1')}}
          </div>
              <div class="p">            
            {{$t('m.in2')}}
          </div>
          <div class="p">
            {{$t('m.in3')}}
          </div>
           <div class="p">
            {{$t('m.in4')}}
          </div>
             <div class="p">
            {{$t('m.in7')}}
          </div>
           <div class="p">
            {{$t('m.in5')}}
          </div>
          
           <div class="p">
            {{$t('m.in6')}}
          </div>
        </div>

    </div>
    <!-- <div class="list" :class="show ? 'h5' : ''">
      <div class="item" @click="wallet">
          <div class="left">Metamask</div>
          <div class="right">
              <img src="../assets/img/my-nft/metamask.png" alt="">
          </div>
      </div>

    </div> -->
    <mbottom />
  </div>
</template>
<script>
import mtop from "../components/toptrx.vue";
import mbottom from "../components/bottom.vue";
import { identity,getuseryz,transferNft } from "@/api/Public";
export default {
  components: {
    mtop,
    mbottom,
  },
  data() {
    return {
           show: false,
           xhloading:false,
           bhnum:'',
    };
  },
   mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
    watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },  
    deep: true,
    immediate: true,
  },
 
     computed: {
    TrxWallet() {
      if (this.$store.getters.TrxWallet) {
        return this.$store.getters.TrxWallet
      } else {
        return "";
      }
    },
     },
  methods:{
        _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
dispose(){
    if(this.TrxWallet&&this.bhnum){
        this.xhloading=true
        getuseryz(this.bhnum,this.TrxWallet).then(res=>{
            if(res){
            transferNft(this.bhnum,this.TrxWallet).then(Trxhx=>{
                if(Trxhx){
                    this.puntAddress(Trxhx)
                }else{                    
                   this.xhloading=false
                }
            })
            }else{
                this.$notify(this.$i18n.t("m.qsrzq"));
                this.xhloading=false
            }
            }
        )


    }else{
        this.$notify("Use the Tron address to log in to the game");
    }
},
     
  
     wallet() {
      var i = 0;
      var a = setInterval(() => {
       if (typeof window.tronWeb !== "undefined") {
          identity().then(
            (info) => {
              clearInterval(a);
               if (info.indexOf("T") == 0) {
                console.log(info);
                this.$store.commit("TrxWallet", info);  
                this.$forceUpdate()             
              } else {
                this.$notify("Use the TRX address to log in to the game");
              }
            },
            (e) => {
              // this.$notify(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    puntAddress(trxRid){
          let time = new Date().getTime();
                let str1 = 'I0wgjYDj';
                let str2 = '$fwFq7QgPm5Gui7';
                let str3 = 'jqged!%gO';                
                let sha256 = this.$sha256(time + str1 + str2 + str3);
                this.$axios({
                  method: "POST",
                  url: `/api/whitelist?sign=${sha256}&t=${time}`,
                  data: {
                    "contract_address_id": 1,
                    "tron_address": this.$store.getters.TrxWallet,
                    "amount": this.bhnum,
                    "hash":trxRid
                  }
                }).then((response) => {
                //    this.$router.replace({ path: '/' });
                console.log(response.data);
                
                this.xhloading=false
                if(response.data.code==0){
                  // console.log(response,77777777)
                  this.$notify({message:this.$i18n.t("m.qhcg"),type:'success'});
                  }else{
                    this.$notify(response.data.msg)
                  }
                })
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
    padding-top: 70px;
    background: #ffffff;

.card{
    width: 672px;
// min-height: 518px;
background: #FFFFFF;
box-shadow: 0px 4px 16px 8px rgba(227,224,224,0.46);
border-radius: 20px;
margin: 148px auto 0;
padding: 32px 0;
 .title{
  font-size: 40px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #192638;
line-height: 56px;
text-align: left;
padding: 24px;
  width: 448px;
 }
 .item{
    background: #FFFFFF;
border-radius: 10px;
border: 1px solid #E4E4E4;
margin: 22px;
padding: 26px;
    .txt{
        font-size: 20px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 28px;
text-align: left;
    }
    .my{
        font-size: 28px;
font-family: DINAlternate-Bold, DINAlternate;
font-weight: bold;
color: #000000;
line-height: 32px;
text-align: left;
margin-top: 16px;
    }
    .input{
        display: flex;
        align-items: flex-start;
        .sinpt{
            border: none;
            margin: 10px;
            padding: 0;
            line-height: 48px;
            font-size: 36px;
            text-align: left;
            flex: 1;
            ::v-deep input{
              line-height: 48px;
            }
        }
    }
 }
  .wallet{
border-radius: 10px;
border: 1px solid #E4E4E4;
display: flex;
align-items: center;
justify-content: space-between;
margin: 22px;
padding: 26px;
cursor: pointer;
         .left{
             font-size: 15px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #192638;
line-height: 21px;
         }
         .right{
             width: 50px;
             height: 50px;
             font-size: 0;
              img{
        width: 100%;
        height: 100%;
    }
         }
     }
 .btn{
    min-width: 400px;
height: 84px;
background: #FCD535;
border-radius: 60px;
font-size: 32px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #222222;
line-height: 84px;
margin: 20px auto;
border: none;
padding: 0 20px;
 }
 
}



.tips{
    padding: 38px;
    .txt{
        font-size: 32px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #192638;
line-height: 44px;
text-align: left;
    }
    .sinfo{
        font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #556370;
line-height: 50px;
margin-top: 16px;
text-align: left;
    }
}

.web{
  width: 545px;
  padding: 10px;
  .title{
    font-size: 30px;
    padding: 10px 21px 0;
  }
  .box{
    .item{
      padding: 16px;
      .txt{
        font-size: 16px;
      }
      .my{
        font-size: 18px;
        margin-top: 0px;
      }
        .input{
        .sinpt{
            margin: 5px 0;
            line-height: 28px;
            font-size: 20px;
               ::v-deep input{
              line-height: 28px;
            }
        }
    }
    }
  }
 
  .bottom{
    .btn{
      min-width: 200px;
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      margin:  0 auto 18px;
    }
  }

}
  .webt{
    width: 1096px;
    margin: 0 auto;
    .txt{
    font-size: 16px;
    line-height: 22px;
    }
    .sinfo{
      font-size: 16px;
line-height: 25px;
margin-top: 10px;
.p{
  margin: 10px 0;
}
    }
  }

 .list{
   margin-bottom: 62px;
         min-height: 60vh;
     .item{
  width: 448px;
height: 63px;
border-radius: 16px;
border: 2px solid #E6E8EC;
display: flex;
align-items: center;
justify-content: space-between;
margin: 20px auto;
padding: 0 23px;
cursor: pointer;
         .left{
             font-size: 15px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #192638;
line-height: 21px;
         }
         .right{
             width: 50px;
             height: 50px;
             font-size: 0;
              img{
        width: 100%;
        height: 100%;
    }
         }
     }
     .item:active{
       border: 1px solid #FCD535;
     }
 }
 .h5{
     .item{
         width: 632px;
height: 100px;
border-radius: 16px;
border: 2px solid #E6E8EC;
display: flex;
align-items: center;
justify-content: space-between;
margin: 20px auto;
padding: 0 36px;
.left{
    font-size: 30px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #192638;
line-height: 42px;
}
.right{
    width: 78px;
    height: 78px;
    font-size: 0;
    img{
        width: 100%;
        height: 100%;
    }
}
     }
 }
}
</style>