import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Activity from "../views/Activity.vue";
import ActivityDetail from "../views/ActivityDetail2.vue";
import ActivityDetailApprove from "../views/ActivityDetailApprove.vue";
import ActivityDetailWhite from "../views/ActivityDetailWhite.vue";
import MyNFT from "../views/MyNFT.vue";
import EditPersonInfo from "../views/EditPersonInfo.vue";
import Empty from "../views/Empty.vue";
import Connect from "../views/Connect.vue";
import Dispose from "../views/Dispose.vue";
 


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/activity",
    name: "Activity",
    component: Activity,
  },
  {
    path: "/activityDetail2",
    name: "ActivityDetail",
    component: ActivityDetail,
  },
  {
    path: "/activityDetailWhite",
    name: "ActivityDetailWhite",
    component: ActivityDetailWhite,
  },
  {
    path: "/activityDetailApprove",
    name: "ActivityDetailApprove",
    component: ActivityDetailApprove,
  },
  {
    path: "/myNFT",
    name: "MyNFT",
    component: MyNFT,
  },
  {
    path: "/editPersonInfo",
    name: "EditPersonInfo",
    component: EditPersonInfo,
  },
  {
    path: "/empty",
    name: "Empty",
    component: Empty,
  },
  {
    path: "/Connect",
    name: "Connect",
    component: Connect,
  },
  {
    path: "/Dispose.html",
    name: "Dispose",
    component: Dispose,
  },
  
  
];

const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) { //设置回到顶部
    if (savedPosition) {
        return savedPosition
    }
    return {x: 0, y: 0}
  }
})

export default router
