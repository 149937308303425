
import abi from './abi.json'

let tronWeb = window.tronWeb

let i = setInterval(() => {
    if ((typeof window.tronWeb) !== 'undefined') {
        tronWeb = window.tronWeb
        clearInterval(i)
    }
}, 1000);
export async function identity() {
    if (tronWeb !== 'undefined') {
        return tronWeb.defaultAddress.base58;
    }
    return false;
}
// 3009701
const ADDRESS_ETH='TRqVqfmqKBZuHHpLsb4bBr6Ac1j6mPfrPg'

const get_HT="TB2AL8vMMX2mKVhYAiZcgiiumk5THZAunh"




export function getuseryz(num,address) {
  let run = async () => {
    // console.log(num,address);
    let unioContract = await tronWeb.contract().at(ADDRESS_ETH)
    let userBalance = await unioContract.ownerOf(num).call().catch(()=>{
      return false
    });      
      // console.log(tronWeb.address.toHex(address),userBalance);
      if(tronWeb.address.toHex(address)==userBalance){
        return true
      }else{ return false}
};
return run();
}




export function stake(amount,address) {
    // console.log(amount);
    let run = async () => {   
        let unioContract = await tronWeb.contract().at(ADDRESS_ETH)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.stake(num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}

//HT转账
export function transferNft(num,address) {
    let run = async () => {
     let unioContract = await tronWeb.contract().at(ADDRESS_ETH)
    let userBalance = await unioContract.ownerOf(num).call();
    if(tronWeb.address.toHex(address)==userBalance){  
        let result=await  unioContract.transferFrom(address,get_HT,num).send({from:address,shouldPollResponse:false}).catch(res=>{
           console.log("err",res);
       }).catch(err=>{ console.log(err.message)})
      //  console.log("res",result);
       return result
    }else{
        return false
    }   
  };
  return run();
}

export function withdraw(amount,address) {
    console.log(amount);
    let run = async () => {
        let unioContract = await tronWeb.contract().at(ADDRESS_ETH)
        let num =  tronWeb.toHex(amount*10**6);
        let result = await unioContract.withdraw(num).send({from:address}).catch(err => {
           console.log(err);
        });
    return result;
  };
  return run();
}









