<template>
  <div class="main">
    <mtop @handleFiexdToolItem="handleFiexdToolItem" />
    <div class="" :class="show ? 'h5' : ''">
      <div class="sheader" id="mint">
        <div class="haerbox">
        <h1 class="banner-title">{{$t('m.wmxl')+$t('m.wmxls')}}</h1>
        <div class="cop">
      {{$t('m.wmxljj')}}
        </div>
        </div>
      </div>

      <div class="white">
        <div class="card">
          <div class="box">
            <div class="intro">
              <div class="left fadeInLeft">
                <img
                  src="../assets/img/activity/details1.png"
                  alt=""
                  class="img1"
                      v-if="!show"
                />
              </div>
              <div class="middle fadeInRight" >
                  <div v-if="show" class="imglist"> 
                   <img
                  src="../assets/img/activity/details1.png"
                  alt=""
                  class="img1"
                />
                 <img
                  src="../assets/img/activity/details2.png"
                  alt=""
                  class="img1"
                />
                </div>

                <!-- <div v-else> -->
                <div class="p1">{{ Maxnum }}/200000</div>
                <div class="p2">{{contAddress|yingcang}}</div>
                <div class="p3">
                  <!-- <img src="../assets/img/activity/icon.png" alt="" /> -->
                  <!-- <span v-if="mlist.indexOf(userWallet) !== -1">Free</span> -->
                  <span>{{ (price * buynum).toFixed(2) }}</span>
                  <span class="dew">BNB</span>
                </div>
                <van-stepper v-model="buynum" :max="50" class="mint"  :min="0" @change="jsmint" input-width="80px" :disabled="sqloading" button-size="32px" />

                <div v-if="!userWallet" class="check-btn" @click="wallet()">
                  {{$t('m.glqb')}}
                </div>

                <van-button
                  type="primary"
                  v-if="userWallet"
                  class="check-btn"
                  :disabled="mintDisabled"
                  @click="toMint()"
                  :loading-text="$t('m.zzz')"
                  :loading="sqloading"
                  >{{$t('m.djzz')}}</van-button
                >
                <!-- </div> -->
              </div>
              <div class="right fadeInRight">
                <img
                  src="../assets/img/activity/details2.png"
                  alt=""
                  class="img1"
                      v-if="!show"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
     <div class="rule">
        <div class="card">
          <p style="font-weight: 600">{{$t('m.fxgz')}}</p>
           <div class="p">            
            {{$t('m.fxmx2')}}
          </div>
            <div class="p">            
            {{$t('m.zzxz')}}
          </div>
          <div class="p">
             {{$t('m.gfsm2')}}
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="Mintshow"
      round
      :close-on-click-overlay="sqloading"
      closeable
    >
      <div class="popup" :class="show ? 'popuph5' : ''" v-if="!sqloading">
        <div class="title">{{$t('m.gxnhd')}}</div>
        <div class="boxs" :class="buynum>2?'mh':'ah'" >
          <div class="item fadeInUp" v-for="(item, i) in list" :key="i">
            <!-- <img :src="item" alt="" class="sbg" v-if="item" /> -->
             <div :class="buynum>2?'bhs':'bh'" v-if="!sqloading">
              <span class="idtxt">{{
                item.id
              }}</span>
            </div>

             <div :class="buynum>1? 'mbg':'mbg1'" v-if="!sqloading">
              <video
                :src="item.image"
                class="img"
                v-if="item.isVideo"
                autoplay
                loop
              ></video>
              <img :src="item.image" alt="" v-else class="img" />
            </div>
          </div>
          <div class="mbg" v-if="sqloading"> 
          <img src="../assets/img/mbg.png" alt="" class="sbg" />
          <div class="zzz">{{$t('m.zzz')}}</div>
          </div>
        </div>
        <div class="bottom">
          <van-button
            v-if="!sqloading"
            type="primary"
            @click="close"
            class="btn"
          >
            <!-- loading-text="铸造中..." :loading="sqloading" -->
            {{$t('m.qr')}}</van-button
          >
        </div>
      </div>
    </van-popup>

    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import {
  connectTron,
  ConstructMerkleTree2,
  getMode,
  getCost2,
  tokenURI2,
  walletOfOwner2,
  gettotal2,
  getRevealed,
  getApprove2
} from "@/api/Public_approve";
export default {
  data() {
    return {
      mintDisabled: false, // 铸造按钮禁用
      show: false,
      timer: false,
      screenWidth: document.body.clientWidth,
      sqloading: false,
      buynum: 1,
      Cbuy: true, // 是否售罄，超过最大购买量   false 售罄
      Mintshow: false,
      Cmint: true, // 是否可以铸造
      list: {},
      mode: 4, // 1:白名单  2.普通 白名单为[]
      mlist: [], // 白名单
      price: 0, // 价格
      Maxnum: 0, // 当前铸造数量
      Max: 1, // 最大可铸造的数量
      mp4: true, // 是否是视频
      contAddress:'0xD84432A9FAbc04116BEf874D0C6385b74'
    };
  },
  components: {
    mtop,
    mbottom,
  },
  computed: {
    // 用户地址
    userWallet() {
      if (this.$store.getters.userWallet) {
        return this.$store.getters.userWallet.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
    },
     // 登陆类型
    type() {
      if (this.$store.getters.ltype) {
        return this.$store.getters.ltype
      } else {
        return "1";
      }
    },
  },
    filters: {
    // 用户地址
    yingcang(e) {
      if (e) {
        return e.replace(
          /(\w{8})\w+(\w{8})/,
          "$1******$2"
        );
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  created() {
    this.getModer();
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$store.getters.sid": function (val) {
      this.handleFiexdToolItem(val);
    },
    "$store.getters.userWallet": function () {
      this.getModer();
    },

    deep: true,
    immediate: true,
  },
  methods: {
      jsmint(){
      if(this.buynum>0){
        this.mintDisabled=false
      }else{
       this.mintDisabled=true
      }
    },
    tourl(e) {
      window.open(e);
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 关联钱包
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                // console.log(info[0]);
                this.$store.commit("userWallet", info[0]);

                // 账户存到后台
                let time = new Date().getTime();
                let str1 = 'I0wgjYDj';
                let str2 = '$fwFq7QgPm5Gui7';
                let str3 = 'jqged!%gO';
                
                let sha256 = this.$sha256(time + str1 + str2 + str3);

                this.$axios({
                  method: "POST",
                  url: `/api/accounts?sign=${sha256}&t=${time}`,
                  data: {
                    address: this.userWallet
                  }
                }).then((response) => {
                  // console.log(response,77777777)
                  // this.$toast.success('关联成功！');
                })
              } else {
                this.$notify("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$notify(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },
    // 获取模式
    getModer() {
      // getMode().then((res) => {
      //   console.log(res, 9090909);
      //   this.mode = res;
      //   // if (this.mode == 2 && this.$store.getters.userWallet) {
      //   //   this.$get({
      //   //     url: "mint/checkmint",
      //   //     data: { address: this.$store.getters.userWallet },
      //   //     callback: (res) => {
      //   //       this.$nextTick(function () {
      //   //         if (res.data) {
      //   //           this.buynum = 1;
      //   //           this.Max = res.data;
      //   //           this.Cmint = true;
      //   //         } else {
      //   //           this.buynum = 0;
      //   //           this.Cmint = false;
      //   //         }
      //   //       });
      //   //     },
      //   //   });
      //   // }
      //   if (this.mode == 4) {
      //     this.Cmint = true;
      //     this.Max = 2;
      //     this.buynum = 1;
      //   }
      // });
      getCost2(this.type).then((res) => {
        // console.log(res, "cost");
        this.price = res;
      });
      gettotal2(this.type).then((res) => {
        if (res >= 200000) {
          this.Cbuy = false;
        }
        this.Maxnum = res;
        // console.log(res)
      });

      // getRevealed().then((res) => {
      //   // console.log(res,787878);
      //   // this.mp4 = res;
      // });
    },

    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    topage(e) {
      this.$router.replace({ path: e });
    },
    toCread() {},
    // 加
    up() {
      if (this.sqloading || !this.Cmint) {
        return;
      }
      this.buynum =
        this.buynum <= this.Max ? this.Max : Number(this.buynum) + 1;
    },
    // 减
    dow() {
      if (this.sqloading || !this.Cmint) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },

    toMint() {
 
      this.mintDisabled = true;
      //  this.list.length = this.buynum;

      // this.Mintshow=true
      // this.getList()
      // this.mintDisabled = false;
      // return

      // 判断mode  mode: 1  白名单列表   2 正常铸造   3 售罄
      // if (this.buynum <= 0 || this.sqloading) {
      //   return;
      // }
      this.sqloading = true;
      let userWallet = this.$store.getters.userWallet;

      let num = this.price * this.buynum;

      // getApprove2(this.$store.getters.userWallet, num,this.type).then((response) => {
      //   // console.log(response,90909090)  
      //   if(response){
         ConstructMerkleTree2(userWallet, this.buynum,this.type)
          .then((res) => {
            // console.log(res, 56566);
            if (res) {
              this.getList();
              this.Mintshow = true;
              this.Cmint = true;
              this.mintDisabled = false;
            } else {
              this.Cmint = false;
              this.sqloading = false;

              this.mintDisabled = false;
            }
          })
          .catch(() => {
            this.mintDisabled = false;
            this.sqloading = false;
          });
        // }else{
        //    this.mintDisabled = false;
        //    this.sqloading = false;
        // }
      // })
    },
    // 获取当前用户所有NFT
    getList() {
      this.list = {};
      
      walletOfOwner2(this.$store.getters.userWallet).then((res) => {
        // console.log("res",res);
        var list = res.slice(-this.buynum);
        // console.log("list",list);
        // this.list={}
        list.forEach((el) => {
          // console.log('el',el);
          this.getUrl(el);
        });
      });
    },

    getUrl(e) {
      tokenURI2(e,this.type).then((res) => {
        // console.log("url", res);
        // let regs = new RegExp("https://ipfs.io/", "g");
        // var urls = res.replace(regs, "https://www.cupidlovemetaverse.com/");
        this.$axios({
          method: "GET",
          url: res,
        }).then((response) => {
          // console.log(response.data);
          let reg = new RegExp("ipfs://", "g");
          var url = response.data.image.replace(
            reg,
            "https://ipfs.fastiii.top:18088/ipfs/"
          );
          // console.log(url);
         
          this.list[e] =  {'image':url,id:'PCCR#'+e}
          // console.log("list", this.list);
          this.sqloading = false;
          this.$forceUpdate();
        });
      });
    },
    close() {
      gettotal2(this.type).then((res) => {
        if (res >= 666) {
          this.Cbuy = false;
        }
        this.Maxnum = res;
      });
      this.Mintshow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";

.main {
  background: #ffffff;
  .sheader {
    background: url("../assets/img/activity/banner2.png") no-repeat top center;
    background-size: cover;
    min-height: 600px;
    width: 100%;
    .haerbox{
      background: rgb(23 23 25 / 56%);
      width: 100%;
      height: 100%;
      min-height: 8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    .banner-title {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 50px;
      color: #ffffff;
      //   margin-left: 320px;
    }
       .cop {
     width: 670px;
height: 136px;
font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 34px;
text-align: left;
margin: 26px auto 0;
      }
    p {
      width: 840px;
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 37px;
      color: #ffffff;
      text-align: left;
      margin: 0 auto;
    }
    }
  }
  .sbtn {
    background: #171719;
    // background: #000000;
    height: 53px;
    width: 100%;
  }
  .gray {
    background: #c8c8c8;
    padding: 50px 0;
    min-height: 746px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tt4 {
      font-size: 30px;
      font-family: $t-f;
      font-weight: 600;
      color: #000000;
      line-height: 42px;
    }
    .img2 {
      // width: 100%;
    }
  }

  .white {
    // min-height: calc(100vh - 70px);
    padding: 130px 0;
    // display: flex;
    // align-items: center;
    .card {
      //   width: 1100px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      justify-content: center;

      .box {
        .intro {
          display: flex;
          justify-content: space-between;
          margin-bottom: 217px;
          align-items: center;
          .left {
            margin-right: 72px;
            .img1 {
              width: 195px;
              height: 195px;
            }
          }
          .middle {
            width: 747px;
            height: 404px;
            background: #F7F8FB;
            border-radius: 20px;
            border: 1px solid #e6e8ec;
            margin-right: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .p1 {
              font-size: 30px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #192638;
              line-height: 42px;
              margin-bottom: 10px;
            }
            .p2 {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #556370;
              line-height: 25px;
              margin-bottom: 46px;
            }
            .p3 {
              font-size: 30px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #192638;
              line-height: 42px;
              margin-bottom: 20px;
              img {
                width: 12px;
                height: 21px;
              }
              .dew{
                font-size: 23px;
                margin-left: 10px;
              }
            }
            .check-btn {
              width: 200px;
              height: 60px;
             background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
              border-radius: 16px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #222222;
              line-height: 60px;
              text-align: center;
              margin-top: 20px;
              margin-bottom: 26px;
              cursor: pointer;
              border: 0;
            }
            .nos{
              background: #c9c9c9;
            }
            .p4 {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #556370;
              line-height: 25px;
            }
          }
          .right {
            justify-content: flex-start;
            h3 {
              text-align: left;
              font-size: 30px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #192638;
              line-height: 42px;
            }
            .img1 {
              width: 195px;
              height: 195px;
            }
            .intro-text {
              width: 398px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #556370;
              line-height: 25px;
              margin-bottom: 36px;
            }
          }
        }
        .intro:last-child {
          margin: 0;
        }
      }
    }
  }

  .rule {
    width: 100%;
    padding: 50px 0 60px;
    background: #F7F8FB;
    .card {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      font-size: 26px;
      .p {
        width: 1096px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #556370;
        line-height: 25px;
      }
    }
  }

  .hzhb {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tt {
      font-size: 38px;
      font-family: $t-f;
      font-weight: bold;
    }
    img {
      width: 1112px;
      height: 351px;
      margin: 71px auto;
    }
  }
  .popup {
    // width: 1200px;
    // background: #171719;
    // border: 6px solid #78c2fa;
    background: #ffffff;
    border-radius: 20px;
    // border: 1px solid #777777;
    .title {
      height: 64px;
      font-size: 28px;
      font-weight: 600;
      color: #333333;
      margin-top: 20px;
    }
    .boxs {
      padding: 10px 30px;
      height: 600px;
      overflow: auto;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      .item {
        // height: 350px;
        // width: 270px;
        margin: 20px 10px 20px 0;
        position: relative;
      }
      // .item:last-child {

      // }
      .sbg {
       height: 340px;
        width: 340px;
        border-radius: 10px;
        cursor: pointer;
      }

      .mbg {
        height: 264px;
        width: 264px;
        border-radius: 20px;
        // border: 1px solid #a5a6a7;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          height: 264px;
          width: 264px;
          border-radius: 20px;
        }
      }
      .mbg1{
         height: 340px;
        width: 340px;
        border-radius: 20px;
        // border: 1px solid #a5a6a7;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
         height: 340px;
        width: 340px;
          border-radius: 20px;
        }
      }
      .zzz {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        font-weight: 500;
        font-family: $t-f;
        color: #000000;
        line-height: 34px;
        white-space: nowrap;
      }
     .bh {
        min-width: 100px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2a2b52;
        margin-bottom: 24px;
        .gxtxt{
font-size: 28px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2B52;
line-height: 40px;
        }
        .idtxt{
font-size: 20px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2B52;
line-height: 28px;
        }
      }
      .bhs {
        min-width: 100px;
            position: absolute;
    bottom: 0;
    width: 100% ;
    border-radius: 0 0 20px 20px;
   height: 56px;
background: rgba(255, 255, 255, 0.1);
backdrop-filter: blur(10px);

        .gxtxt{
font-size: 28px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2B52;
line-height: 40px;
        }
        .idtxt{
    font-size: 24px;
font-family: Helvetica;
color: #FFFFFF;
text-align: center;
line-height: 56px;
        }
      }
    
    }
     .mh{
      height: 600px;
    }
    .ah{
      min-height: 100px;
      height: auto;
    }
    .bottom {
      // background: #ffffff;
      height: 120px;
      .btn {
        width: 295px;
        height: 52px;
        background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
        border-radius: 12px;
        font-size: 32px;
        font-weight: 600;
        color: #222222;
        line-height: 52px;
        margin-top: 20px;
        cursor: pointer;
        border: none;
      }
    }
  }
  .popuph5 {
    width: calc(100vw - 100px);
    .title{
      font-size: 32px;
      color: #333333;
      margin-top: 48px;
      height: 40px;
    }
    .boxs {
      justify-content: center;
   
     .mbg1{
        width: 590px;
        height: 590px;
        .img{
          width: 590px;
          height: 590px;
        }
      }
    }
    .bh{
      font-size: 36px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2B52;

.gxtxt{
font-size: 36px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2B52;
line-height: 50px;
}
.idtxt{
font-size: 24px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #2A2B52;
line-height: 34px;
}
    }
       .bottom{
    width: 100%;
    background: #ffffff;
    padding-bottom: 80px;
      .btn{
width: 590px;
height: 104px;
background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
border-radius: 24px;
      }
    }
  }

  .h5 {
    .sheader{
    }
    .banner-title {
      width: 670px;
      padding: 0px 20px 0;
      font-size: 36px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #ffffff;
      margin: 0 auto;
      line-height: 36px;
      text-align: left;
      
    }
    .cop {
     width: 670px;
height: 136px;
font-size: 24px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 34px;
text-align: left;
margin: 26px auto 0;
      }
    .header {
      width: 100%;
      padding-top: 100px;
      background: linear-gradient(
        -65deg,
        #ebecff 0%,
        #f8f9fd 67%,
        #e8fafc 100%
      );
      .left {
        // background: url('../assets/imgh/stop.png') no-repeat;
        background-size: 253px 267px;
        height: 267px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 60px;
        .tt1 {
          font-size: 50px;
          font-family: $t-f;
          font-weight: bold;
          color: #4458fe;
        }
        .tt2 {
          font-size: 33px;
          font-family: $t-f;
          font-weight: bold;
          color: #333333;
        }
      }
      .right {
        img {
          width: 100%;
        }
      }
    }

    .cardh {
      .left {
        margin: 31px 61px;
        .tt1 {
          font-size: 42px;
          font-family: $t-f;
          font-weight: bold;
          color: #4458fe;
        }
        .tt2 {
          font-size: 42px;
          font-family: $t-f;
          font-weight: bold;
          color: #333333;
          margin-top: 17px;
          margin-bottom: 30px;
        }
        .tt3 {
          font-size: 25px;
          font-family: $t-f;
          font-weight: 400;
          color: #333333;
        }
      }
      .right {
        margin-top: 31px;
        img {
          width: 100%;
        }
      }
    }
    .cs {
      background: linear-gradient(38deg, #faf1ec 0%, #eeeef9 52%);
      margin-top: -34px;
      padding-top: 34px;
    }
    .shzhb {
      width: 100%;
      margin: 50px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .tt {
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
      }
      img {
        width: 698px;
        height: 219px;
        margin: 71px auto;
      }
    }
 
  }
  .h5 {
    .sheader {
      .herder {
        width: 100%;
        .left {
          width: calc(100% - 40px);
          margin: 20px;
          .box {
            width: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px;
            .stip {
              font-size: 23px;
              margin-bottom: 10px;
              margin-right: 0;
            }
            .mint {
              margin-left: 0;
              .input {
                margin-right: 30px;
              }
            }
            .creadw {
              margin-left: 0;
            }
            .middle {
              margin-right: 0;
            }
          }
        }
      }
    }
    .white {
      padding: 0;
      h3 {
        font-size: 26px;
      }
      .card {
        flex-direction: column;
        .box {
          flex-direction: column;
          .intro {
            flex-direction: column;
            .middle {
              margin:24px 0;
                .mint {
              margin-left: 0;
            ::v-deep .van-stepper__input {
                font-size: 30px;
              }
            }
            }
            .middle {
              border-radius: 0;
              padding: 80px 0;
              height: auto;
              width: 690px;
              background: #F7F8FB;
border-radius: 16px;
border: 2px solid #E8E8E8;
.imglist{
  display: flex;
  align-items: center;
  .img1{
    width: 160px;
    height: 160px;
  }
  .img1:nth-child(2){
    margin-left: -50px;
    border-radius: 100%;
    border: 2px solid #ffffff;
  }
}
              .p1{
                font-size: 60px;
                font-weight: 600;
                color: #192638;
                line-height: 84px;
                margin: 56px 0 10px;
              }
              .p2{
                font-size: 32px;
                font-weight: 400;
                color: #556370;
                line-height: 50px;
              }
              .p3{
                font-size: 60px;
                font-weight: 600;
                color: #192638;
                line-height: 84px;
                
                img{
                  width: 24px;
                  height: 42px;
                  margin-right: 28px;
                }
              }
              .check-btn{
                margin:42px 0 24px;
               width: 272px;
height: 84px;
line-height: 84px;
font-size: 32px;
font-weight: 600;

border-radius: 32px;
              }
            }
          }
        }
      }
    }
    .rule {
      padding: 50px 20px 60px;
      .p{
      width: 670px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #556370;
      line-height: 50px;
      margin: 10px 0;
      }
    }
  }
}
</style>