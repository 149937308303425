<template>
  <div class="main">
    <mtop @handleFiexdToolItem="handleFiexdToolItem" />
    <div class="" :class="show ? 'h5' : ''">
      <div class="sheader" id="mint" :style="user&&user.banner?'backgroundImage:url('+user.banner+')':''">
      </div>
      <div class="my-profile">
        <div class="my-container">
          <div class="user-icon">
            <img
              :src="user&&user.avatar?user.avatar:require('@/assets/img/my-nft/user-icon.png')"
              alt=""
              class="user-img"
            />
          </div>
          <div class="userinfo">
          <h3>{{ user&&user.username!='匿名数藏者'?user.username:userWallet.substr(2, 6) }}</h3>
          <div class="my-wname">
            {{userWallet|yingcang }}
                <button
            type="button"
            class="fuzhi"
            v-clipboard:copy="userWallet"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <img src="../assets/img/my-nft/icon1.png" alt="" class="wicon" />
          </button>
          </div>
          <div class="con-container" v-if="!show">
            <div
              class="my-content fadeInUp"
              :style="{ height: txtHeight, overflow: txtOverflow }"
            >
            {{user?user.description?user.description:$t('m.zwwtx'):'隐藏在元宇宙中。Invisible Friends 是由 Markus Magnusson 创作的 5000 个动画隐形角色的集合。隐形朋友是一个随机角色集体项目。另见隐形朋友特价。'}}
             </div>           
          </div>
          </div>
          <div class="user-btn">
            <div class="btn"  @click="toslloc('/editPersonInfo')">{{$t('m.bj')}}</div>
          </div>
        </div>
         <div class="con-container" v-if="show">
            <div
              class="my-content fadeInUp"
              :style="{ height: txtHeight, overflow: txtOverflow }"
            >
            {{user?user.description?user.description:$t('m.zwwtx'):'隐藏在元宇宙中。Invisible Friends 是由 Markus Magnusson 创作的 5000 个动画隐形角色的集合。隐形朋友是一个随机角色集体项目。另见隐形朋友特价。'}}
             </div>           
          </div>
      </div>


      <div class="white">
        <!-- tab切换 -->
              <div class="search">
                <div class="box">
                  <div class="result">{{$t('m.scps')}} <span> ({{ resultNum+resultNum2+resultNum3 }} {{$t('m.jg')}})</span></div>
                </div>
              </div>
              <!-- 内容 -->
              <div class="card">
                <div class="my-list">
                  <div class="item fadeInUp" v-for="(item, i) in list" :key="i">
                    <img :src="item.image" alt="" class="list-img"/>
                    <div class="list-intro">
                      <div class="intro-1">
                        <div>
                          <span class="txt1">{{item.cname}}</span>
                          <img
                            src="../assets/img/my-nft/list-icon.png"
                            alt=""
                            class="img01"
                          />
                        </div>
                      </div>
                      <div class="intro-1">
                        <span class="txt2">{{ item.name }}</span>                  
                      </div>
                    </div>
                  </div>



                   <div class="item fadeInUp" v-for="(item, i) in list2" :key="i">
                    <img :src="item.image" alt="" class="list-img"/>
                    <div class="list-intro">
                      <div class="intro-1">
                        <div class="stitle">
                          <span class="txt1">{{item.cname}}</span>
                          <img
                            src="../assets/img/my-nft/list-icon.png"
                            alt=""
                            class="img01"
                          />
                        </div>
                      </div>
                      <div class="intro-1">
                        <span class="txt2">{{ item.name }}</span>                  
                      </div>
                    </div>
                  </div>

                  <div class="item fadeInUp" v-for="(item, i) in list3" :key="i">
                    <img :src="item.image" alt="" class="list-img"/>
                    <div class="list-intro">
                      <div class="intro-1">
                        <div class="stitle">
                          <span class="txt1">{{item.cname}}</span>
                          <img
                            src="../assets/img/my-nft/list-icon.png"
                            alt=""
                            class="img01"
                          />
                        </div>
                      </div>
                      <div class="intro-1">
                        <span class="txt2">{{ item.name }}</span>                  
                      </div>
                    </div>
                  </div>

                </div>
        
        </div>
        <!-- <div class="page">
          <van-pagination
            v-model="currentPage"
            :total-items="125"
            :show-page-size="3"
            force-ellipses
          />
        </div> -->
      </div>
    </div>

    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import { 
  tokenURI,
 walletOfOwner,
 getContractName,
 tokenURI2,
 walletOfOwner2,
 getContractName2,
  tokenURI3,
 walletOfOwner3,
 getContractName3} from "@/api/Public_approve";
export default {
  data() {
    return {
      activeName: "shoucang",
      currentPage: 1,
      txtHeight: "auto",
      txtOverflow: "auto",
      resultNum: 0,    // 藏品总数
      show: false,
      timer: false,
      screenWidth: document.body.clientWidth,
      sqloading: false,
      buynum: 1,
      Cbuy: true, // 是否售罄，超过最大购买量
      Mintshow: false,
      Cmint: true, // 是否可以铸造
      list: {}, // 我的NFT列表
      mode: 4, // 4：白名单为空
      mlist: [], // 白名单
      price: 0, // 价格
      Maxnum: 0, // 当前铸造数量
      Max: 1, // 最大可铸造的数量
      mp4: true, // 是否是视频
      name:'',
      list2:{},
      resultNum2:0,
      user:'',
      name2:'',
      list3:[],
      resultNum3:0,
      name3:'',
      userWallet:this.$store.getters.userWallet,
      contAddress:'0x52f3787922FbB27A0087e4C70029bFa32C75fE9E',
       contAddress2:'0xD84432A9FAbc04643fEB1116BEf874D0C6385b74'
    };
  },
  components: {
    mtop,
    mbottom,
  },
  filters: {
    // 用户地址
    yingcang(e) {
      if (e) {
        return e.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }   
    this.getList();
     this.getList2();
     this.getList3();
    this.getUserList();
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$store.getters.sid": function (val) {
      this.handleFiexdToolItem(val);
    },

    deep: true,
    immediate: true,
  },
  methods: {
    showText() {
      this.txtHeight = this.txtHeight == "44px" ? "auto" : "44px";
      this.txtOverflow = this.txtOverflow == "hidden" ? "auto" : "hidden";
    },
    // 获取用户资料
    getUserList() {
        this.$get({
            url: "accounts",
            data: { address: this.userWallet },
            callback: (res) => {
              this.$nextTick(function () {
                if (res.data) {
                  this.user = res.data[0];
                } 
              });
            },
          });
    
    },
    tourl(e) {
      window.open(e);
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    topage(e) {
      this.$router.replace({ path: e });
    },
    toCread() {},

    // 获取当前用户所有NFT
    getList() {
      this.list = {};
       getContractName().then(name=>{
         this.name=name
       });
      walletOfOwner(this.userWallet).then((res) => {
        // console.log("res",res);
        this.resultNum = res.length;
        // var list = res.slice(-this.buynum);
        var list = res.slice(-res.length);
        
        // console.log("list", list);
        // this.list={}
        list.forEach((el) => {
          // console.log('el',el);
          this.getUrl(el);
        });
      });
    },

 

    getUrl(e) {
      tokenURI(e).then((res) => {
        // console.log("url", res);
        // let regs = new RegExp("https://ipfs.io/", "g");
        // var urls = res.replace(regs, "https://www.cupidlovemetaverse.com/");
        this.$axios({
          method: "GET",
          url: res,
        }).then((response) => {
          // console.log(response.data.image);
           let reg = new RegExp("ipfs://", "g");
          var url = response.data.image.replace(
            reg,
            "https://gateway.fastiii.top/ipfs/"
          );
          response.data.image=url
          response.data.cname=this.name
          this.list[e] = response.data;
          // console.log("list", this.list);
          this.sqloading = false;
          this.$forceUpdate();
        });
      });
    },
 onCopy() {
      this.$notify(this.$i18n.t("m.fzcg"));
    },
    onError() {
      this.$notify(this.$i18n.t("m.fzsb"));
    },
    getList2() {
      this.list2 = {};
       getContractName2().then(name=>{
         this.name2=name
       });
      walletOfOwner2(this.userWallet).then((res) => {
        // console.log("res",res);
        this.resultNum2 = res.length;
        // var list = res.slice(-this.buynum);
        var list = res.slice(-res.length);
        
        // console.log("list", list);
        // this.list={}
        list.forEach((el) => {
          // console.log('el',el);
          this.getUrl2(el);
        });
      });
    },
 getUrl2(e) {
      tokenURI2(e).then((res) => {
        // console.log("url", res);
        // let regs = new RegExp("https://ipfs.fastiii.top:18088/", "g");
        // var urls = res.replace(regs, "https://gateway.fastiii.top/ipfs/");
        this.$axios({
          method: "GET",
          url: res,
        }).then((response) => {
          // console.log(response.data.image);
           let reg = new RegExp("ipfs://", "g");
          var url = response.data.image.replace(
            reg,
            "https://ipfs.fastiii.top:18088/ipfs/"
          );
          response.data.image=url
          response.data.cname=this.name2
          this.list2[e] = response.data;
          this.sqloading = false;
          this.$forceUpdate();
        });
      });
    },
  getList3() {
      this.list3 = {};
       getContractName3().then(name=>{
         this.name3=name
       });
      walletOfOwner3(this.userWallet).then((res) => {
        // console.log("res",res);
        this.resultNum3 = res.length;
        // var list = res.slice(-this.buynum);
        var list = res.slice(-res.length);
        
        // console.log("list", list);
        // this.list={}
        list.forEach((el) => {
          // console.log('el',el);
          this.getUrl3(el);
        });
      });
    },
    getUrl3(e) {
      tokenURI3(e).then((res) => {
        // console.log("url", res);
        // let regs = new RegExp("https://ipfs.fastiii.top:18088/", "g");
        // var urls = res.replace(regs, "https://gateway.fastiii.top/ipfs/");
        this.$axios({
          method: "GET",
          url: res,
        }).then((response) => {
          // console.log(response.data.image);
           let reg = new RegExp("ipfs://", "g");
          var url = response.data.image.replace(
            reg,
            "https://ipfs.fastiii.top:18088/ipfs/"
          );
          response.data.image=url
          response.data.cname=this.name3
          this.list3[e] = response.data;
          this.sqloading = false;

          this.$forceUpdate();
        });
      });
    },
    toslloc(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style>
.van-tabs__line {
  width: 71px !important;
  height: 3px !important;
  background-color: #fcd535 !important;
}
.van-tab__text {
  font-size: 16px;
  color: #192638;
  line-height: 22px;
}
</style>

<style lang="scss" scoped>
$t-f: "DINPro";
$t-sf:'MiSans';
.main {
  background: #ffffff;
//   .mtop.h5 {
//     background: #101010;
// }
  .sheader {
    background: url('../assets/img/my-nft/mbg.png') ;
    height: 222px;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
}

.my-profile {
  .my-container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding:66px 0 48px;
    position: relative;
    align-items: center;
    .user-icon {
      width: 124px;
      height: 124px;
      background: #f1f1f1;
      border: 6px solid #ffffff;
      border-radius: 50%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .user-img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }


    .userinfo{
      margin-left: 30px;
      flex: 1;
      text-align: left;
    h3 {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #192638;
      line-height: 24px;
      margin: 0;
    }
    .my-wname {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #556370;
      line-height: 22px;
      margin-bottom: 12px;
      .fuzhi {
        background: transparent;
        border: none;
      }
      .wicon {
        width: 16px;
        height: 16px;
        margin-left: 6px;
      }
    }
    .con-container {
      justify-content: center;
      .my-content {
        text-align: left;
        width: 565px;
        // height: 50px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #556370;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .icon-con {
        img {
          width: 10px;
          height: 7px;
          cursor: pointer;
        }
      }
    }
    }
    .user-btn{
      .btn{
        width: 92px;
height: 36px;
background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
border-radius: 12px;
font-size: 14px;
font-family: MiSans-Normal, MiSans;
font-weight: 600;
color: #000000;
line-height: 18px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
      }
    }
  }
}
.white {
  padding: 40px 0 80px;
  background: #F7F8FB;
  // min-height: calc(100vh - 70px);
  // display: flex;
  // align-items: center;
  // position: relative;
  .tab {
    font-size: 32px;
font-weight: 600;
color: #192638;
line-height: 44px;
width: 100%;
border-bottom: 1px solid #E6E8EC;
    .tab-img {
      width: 28px;
      height: 28px;
      margin-top: 10px;
      margin-right: 4px;
    }
     .shoucang{
       min-width: 142px;
       max-width: 200px;
border-bottom:6px solid #FCD535;
margin: 0 auto;
      }
  }

     
  // .page {
  //   width: 400px;
  //   position: absolute;
  //   bottom: 200px;
  //   right: 20%;
  // }
  .search {
    width: 100%;
    min-height: 80px;
    .box {
      width:1200px;
      margin: 0 auto;
      position: relative;
    }
    .result {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2a2b52;
      line-height: 20px;
      position: absolute;
      top: 28px;
      left: 0;
    }
  }
  .card {
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 48px;
    .my-list {
      overflow: auto;
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
      .item {
        width: 363px;
        min-height: 510px;
        border-radius: 8px;
        border: 1px solid #e6e8ec;
        margin-right: 20px;
        margin-bottom: 20px;
        overflow: hidden;

        .list-img {
          width: 364px;
          height: 362px;
        }
        .list-intro {
          padding: 2px 20px 32px;

          .stitle{
            display: flex;
          }
          .intro-1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            .img01 {
              width: 26px;
              height: 26px;
              vertical-align: middle;
              margin-left: 8px;
              margin-top: -4px;
            }
            .img02 {
              width: 13px;
              height: 22px;
              vertical-align: middle;
              margin-right: 6px;
              margin-top: -4px;
            }
            .txt1 {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #959ca4;
              text-align: left;
            }
            .txt2 {
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #2a2b52;
            }
            .txt3 {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #959ca4;
            }
          }

          .offsetRight {
            justify-content: flex-end;
          }
        }
      }
      .item:last-child {
        margin-right: 0px;
      }
    }
  }
}

.hzhb {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .tt {
    font-size: 38px;
    font-family: $t-f;
    font-weight: bold;
  }
  img {
    width: 1112px;
    height: 351px;
    margin: 71px auto;
  }
}
.popup {
  // width: 1200px;
  // background: #171719;
  // border: 6px solid #78c2fa;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #777777;
  .title {
    height: 64px;
    font-size: 28px;
    font-style: italic;
    font-weight: 600;
    color: #ffffff;
    line-height: 64px;
  }
  .boxs {
    padding: 30px;
    // height: 600px;
    overflow: auto;
    display: flex;
    flex-flow: wrap;

    .item {
      height: 270px;
      width: 270px;
      margin: 10px;
      position: relative;
    }
    .sbg {
      height: 264px;
      width: 264px;
      border-radius: 10px;
      cursor: pointer;
    }

    .mbg {
      height: 264px;
      width: 264px;
      border-radius: 20px;
      border: 1px solid #a5a6a7;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        height: 250px;
        width: 250px;
        border-radius: 20px;
      }
    }
    .zzz {
      position: absolute;
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      font-weight: 500;
      font-family: $t-f;
      color: #000000;
      line-height: 34px;
      white-space: nowrap;
    }
    .bh {
      min-width: 100px;
      font-size: 26px;
      font-family: $t-f;
      margin-top: 10px;
      font-weight: 600;
      color: #ffffff;
      white-space: nowrap;
    }
  }
  .bottom {
    // background: #ffffff;
    height: 131px;
    .btn {
      width: 235px;
      height: 52px;
      background: #fcd535;
      border-radius: 26px;
      // width: 235px;
      // height: 56px;
      // background: #4c4cf3;
      // border-radius: 8px;
      // box-shadow: 0px 10px 18px 0px rgb(23 23 25);
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      line-height: 22px;
      // font-weight: bold;
      // color: #ffffff;
      // line-height: 62px;
      margin-top: 38px;
      cursor: pointer;
      border: none;
    }
  }
}
.popuph5 {
  width: calc(100vw - 100px);
  .boxs {
    justify-content: center;
  }
}

.h5 {
  
  .header {
    width: 100%;
    padding-top: 100px;
    background: linear-gradient(-65deg, #ebecff 0%, #f8f9fd 67%, #e8fafc 100%);
    .left {
      // background: url('../assets/imgh/stop.png') no-repeat;
      background-size: 253px 267px;
      height: 267px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 60px;
      .tt1 {
        font-size: 50px;
        font-family: $t-f;
        font-weight: bold;
        color: #4458fe;
      }
      .tt2 {
        font-size: 33px;
        font-family: $t-f;
        font-weight: bold;
        color: #333333;
      }
    }
    .right {
      img {
        width: 100%;
      }
    }
  }
    .my-container{
    .userinfo  h3{
font-size: 34px;
font-weight: 600;
color: #192638;
line-height: 72px;
      }
      .my-wname{
        font-size: 20px;
font-weight: 400;
color: #556370;
line-height: 44px;
 .iconw {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
      }
    
    .con-container .my-content{
    font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #556370;
line-height: 44px;
  }

  
    }

    .white{
     padding: 40px 0 30px;
      .tab{
        font-size: 32px;
font-weight: 600;
color: #192638;
line-height: 44px;
width: 100%;
border-bottom: 1px solid #E6E8EC;
      }
      .shoucang{
       width: 142px;
border-bottom:6px solid #FCD535;
margin: 0 auto;
      }
      .tab-img{
        height: 36px;
        width: 36px;
      }
      .search{
        margin-left: 14px;
  
    .result {
      font-size: 28px;
      margin-left: 14px;
    }
  
      }
      .card{
          flex-direction: column;
      width: auto;
       .my-list{
          width: calc(100% - 26px);
          margin: 0 auto;
        .item{
          width: calc( 50% - 32px);
          flex-direction: row;
          flex-wrap: wrap;
          margin: 13px;
        }
       }
      }
    }
  .cardh {
    .left {
      margin: 31px 61px;
      .tt1 {
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
        color: #4458fe;
      }
      .tt2 {
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
        color: #333333;
        margin-top: 17px;
        margin-bottom: 30px;
      }
      .tt3 {
        font-size: 25px;
        font-family: $t-f;
        font-weight: 400;
        color: #333333;
      }
    }
    .right {
      margin-top: 31px;
      img {
        width: 100%;
      }
    }
  }
  .cs {
    background: linear-gradient(38deg, #faf1ec 0%, #eeeef9 52%);
    margin-top: -34px;
    padding-top: 34px;
  }
  .shzhb {
    width: 100%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tt {
      font-size: 38px;
      font-family: $t-f;
      font-weight: bold;
    }
    img {
      width: 698px;
      height: 219px;
      margin: 71px auto;
    }
  }
}
.h5 {
  .box{
    
    background: #101010;
  }
 
  .sheader {
    background: url('../assets/img/my-nft/hbg.png') ;
    background-size:cover;
    background-position: left center;
    height: 426px;
    .herder {
      width: 100%;
      .left {
        width: calc(100% - 60px);
        margin: 20px;
        .box {
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 40px;
          .stip {
            font-size: 23px;
            margin-bottom: 10px;
            margin-right: 0;
          }
          .mint {
            margin-left: 0;
            .input {
              margin-right: 30px;
            }
          }
          .creadw {
            margin-left: 0;
          }
        }
      }
    }
  }
  .card .my-list .item .list-intro .intro-1{
 .txt1 {
   font-size: 26px;
 }
 .txt2{
   font-size: 28px;
 }
  }
  .my-profile .icon-list{
    .icon1{
      width: 66px;
      height: 66px;
    }
  }
  .my-container {
   padding:  30px;
    width: auto;
    .user-btn .btn{
      width: 96px;
height: 52px;
font-size: 24px;
    }
    .my-wname{
       .wicon {
        width: 26px;
        height:26px;
        margin-left: 6px;
      }
    }
    .con-container {
      flex-direction: column; 
    }
  }
  .con-container{
    border-top: 1px solid #000;
    margin: 0 30px;
    .my-content{
      font-size: 24px;
font-family: $t-sf;
font-weight: 400;
color: #000000;
margin: 40px 0;
line-height: 32px;
text-align: left;
    }
  }
 
}

</style>