<template>
  <div class="">
    <div class="mbottom" :class="show ? 'h5' : ''">
      <!-- <div class="bottom-top">
        <div class="sbottom">
          <div class="logo-1">
            <img src="../assets/img/pp0.png" alt="" />
          </div>
          <div class="logo-2">
            <img src="../assets/img/pp1.png" alt="" />
          </div>
          <div class="logo-3">
            <img src="../assets/img/pp2.png" alt="" />
          </div>
          <div class="logo-4">
            <img src="../assets/img/pp3.png" alt="" />
          </div>
        </div>
      </div> -->

      <div class="bottom-middle">
        <div class="sbottom">
          <div class="left">
            <div class="logo-bottom">
              <img src="../assets/img/logo.png" alt="" />
            </div>
            <div class="tt2">{{$t('m.dysstx')}}</div>
            <div class="bottom-ipt">
              <input type="text" :placeholder="$t('m.ndyxdz')" />
              <span>{{$t('m.dy')}}</span>
            </div>
            <div class="link-list">
              <div class="link-item">
                <img src="../assets/img/link1.png" alt="" @click="to('https://twitter.com/Fist__NFT')" />
              </div>
              <div class="link-item">
                <img src="../assets/img/link2.png" alt=""  @click="to('mailto:kf@fistnft.com')"/>
              </div>
              <div class="link-item">
                <img src="../assets/img/link3.png" alt="" @click="to('https://medium.com/@FistNFT')" />
              </div>
              <div class="link-item">
                <img src="../assets/img/link4.png" alt=""  @click="to('https://t.me/FistNFT')"/>
              </div>
              <!-- <div class="link-item">
                <img src="../assets/img/link5.png" alt="" />
              </div>
              <div class="link-item">
                <img src="../assets/img/link6.png" alt="" />
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="link-text">
              <h4>{{$t('m.ts')}}</h4>
              <div class="linex">
              <div class="text">{{$t('m.qbfl')}}</div>
              <div class="text">{{$t('m.ysp')}}</div>
              <div class="text">{{$t('m.scp')}}</div>
              <div class="text">{{$t('m.xnsj')}}</div>
              </div>
            </div>
            <div class="link-text">
              <h4 v-if="!show"></h4>
               <div class="linex">
              <div class="text">{{$t('m.shiy')}}</div>
              <div class="text">{{$t('m.yyue')}}</div>
              <div class="text">{{$t('m.ty')}}</div>
              <div class="text">{{$t('m.ym')}}</div>
               </div>
            </div>
            <div class="link-text">
              <h4>NFT</h4>
               <div class="linex">
              <div class="text">{{$t('m.cj')}}</div>
              <div class="text">{{$t('m.tj')}}</div>
              <div class="text">{{$t('m.phb')}}</div>
              <div class="text">{{$t('m.xnsj')}}</div>
               </div>
            </div>
            <div class="link-text">
              <h4>{{$t('m.gy')}}</h4>
               <div class="linex">
              <div class="text">{{$t('m.gywm')}}</div>
              <div class="text">{{$t('m.bzzx')}}</div>
              <div class="text"></div>
              <div class="text"></div>
               </div>
            </div>
            <!-- <img
            src="../assets/img/hicon.png"
            alt=""
            class="img"
            @click="to('https://twitter.com/NFTwhalesclub1')"
          />
          <img
            src="../assets/img/hicon1.png"
            alt=""
            class="img"
            @click="to('https://discord.com/invite/nftwhalesclub')"
          /> -->
            <!-- <img src="../assets/img/hicon2.png" alt="" class="img"> -->
          </div>
        </div>
      </div>

      <div class="bottom-foot">
        <div class="sbottom">
          <div class="left">{{$t('m.bqsy')}}@2022 FistNFT</div>
          <div class="right">
            <span>{{$t('m.yssm')}}</span>
            <span>{{$t('m.fwtk')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      show: false,
    };
  },

  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    open(e) {
      window.open(e);
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    to(url) {
      window.open(url);
    },
    topage(e) {
      if (e == this.$route.path) {
        return;
      }
      this.$router.push({ path: e });
    },
    ding() {
      if (this.email) {
        const reg = /^([a-zA-Z0-9]+[-_.]?)+@[a-zA-Z0-9]+.[a-z]+$/;
        if (reg.test(this.email)) {
          this.$notify({
            message: "Subscribe to the success",
            type: "success",
          });
          this.email = "";
        }
      } else {
        this.$notify("Please enter your email number");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
.mbottom {
  background: #101010;
  background-size: 100% 100%;
  // min-height: 498px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bottom-top {
    border-bottom: 1px solid #36363a;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .sbottom {
      display: flex;
      align-items: center;
      width: 1200px;
      justify-content: space-between;
      margin: 0 auto;
      padding: 53px 0;
      .logo-1 {
        // width: 184px;
        height: 34px;
      }
        .logo-2 {
        // width: 156px;
        height: 34px;
      }
        .logo-3 {
        // width: 132px;
        height: 34px;
      }
        .logo-4 {
        height: 24px;
      }
      img{
        height: 100%;
      }
    }
  }
  .bottom-middle {
    border-bottom: 1px solid #36363a;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .sbottom {
      display: flex;
      align-items: center;
      width: 1200px;
      justify-content: space-between;
      margin: 0 auto;
      padding: 40px 0 68px;
      .left {
        width: 30%;
        .logo-bottom {
          margin-bottom: 16px;
          display: flex;
          img {
            width: 130px;
            height: 35px;
          }
        }
        .tt2 {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c5c5c5;
          margin-bottom: 16px;
          text-align: left;
        }
        .bottom-ipt {
          background: #1C1C1C;
          border-radius: 5px;
          padding: 8px 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 26px;
          input {
            margin: 0;
            padding: 0;
            border: 0;
            width: 199px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #556370;
            line-height: 20px;
            background: transparent;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
border-radius: 3px;
padding: 8px 20px;
cursor: pointer;
          }
        }
        .link-list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .link-item {
            width: 48px;
            height: 48px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            img {
              width: 20px;
            }
          }
          .link-item:hover{
           background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40%;
        .link-text {
          // margin-right: 130px;
          h4 {
            height: 20px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 35px;
          }
          .text {
            min-height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #C5C5C5;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  .bottom-foot {
    min-height: 70px;
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C5C5C5;
    // line-height: 17px;
    .sbottom {
      display: flex;
      align-items: center;
      width: 1200px;
      justify-content: space-between;
      margin: 0 auto;
      span:first-child {
        margin-right: 24px;
      }
    }
  }
}
.h5 {
  
  .bottom-middle .sbottom .left
  .logo-bottom {
    img{
      width: 186px;
      height: 50px;
    }
  }

  .bottom-top, .bottom-foot {
    width: auto;
    flex-direction: column;
    .sbottom {
      width: 80%;
     flex-wrap: wrap;
     font-size: 24px;
     justify-content: space-between;
     padding: 36px 0;
     .logo-1{
       width: 50%;
    display: flex;
    img{
      height: 44px;
    }
}
.logo-2{
  width: 50%;
    display: flex;
    justify-content: flex-end;
     img{
      height: 44px;
    }
}
.logo-3{
  width: 50%;
    display: flex;
     margin-top: 30px ;
      img{
      height: 44px;
    }
}
.logo-4{
  width: 50%;
    display: flex;
    justify-content: flex-end;
      margin-top: 30px ;
      img{
        height: 30px;
        width: auto;
      }
}


    }
  }
  .bottom-middle .sbottom{
    margin: 0 auto;
    flex-direction:column;
    .intro-btn img{
width: 42px;
height: 40px;
    }
  }
 .bottom-middle .sbottom .left .tt2{
    font-size: 24px;
    margin: 40px 0 36px;
  
  }
  .logo-b {
    margin-bottom: 30px;
  }
 .bottom-middle .sbottom .left {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
   width: calc(100% - 120px);
    
.bottom-ipt{
width: 578px;
height: 100px;
background: #1C1C1C;
border-radius: 16px;
margin: 10px 0 52px;
  input{
line-height: 62px;
height: 62px;
padding-left: 20px;
font-size: 26px;
background: transparent;
  }
  span{
    font-size: 24px;
font-weight: 600;
color: #000000;
padding:20px 50px;
  }
}
    // margin: 20px auto;
  }
 .bottom-middle .sbottom .left .link-list .link-item{
    margin-right: 45px;
    width: 60px;
    height: 60px;
    img{
      width: 30px;
    }
  } 
 .bottom-middle .sbottom .right {
     display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: calc(100% - 120px);
    .link-text{
      margin: 0;
      h4{
        text-align: left;
      }
    }
    .linex{
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;
      .text{
        font-size: 24px;
        margin-right: 66px;
      }
    }
  }
}
</style>