/**
 * --------活动一--------------
 */

import Web3 from 'web3'

import NFT from './abi-ap.json'       // ERC721 abi
import NFT2 from './abi2.json'       // ERC721 abi
import NFTP from './fil.json'    // 授权ERC20 abi
import NFT3 from './merkleTree.json'    // 授权ERC20 abi
import {whitelist} from './dd'

import store from '../store/index'
const keccak256 = require('keccak256')
import { Toast } from 'vant';
import WalletConnectProvider from "@walletconnect/web3-provider";
//正式
const ADDRESS_NFT_APPROVE = "0xCf55427E6da0e894746b8C06CCB92991550c475E"; // 授权合约地址 ERC20合约

const ADDRESS_NFT= "0x52f3787922FbB27A0087e4C70029bFa32C75fE9E"   //  Mint合约地址 ERC721合约

const ADDRESS_NFT2="0xD84432A9FAbc04643fEB1116BEf874D0C6385b74"  //合约二

const ADDRESS_NFT3="0xDB44357C57c3f9698cc035c9DD3995b27bD85113"  //合约三

const mlist=[]

let ethereum = window.ethereum
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
         handleWatch()  
        ethereum = window.ethereum        
        clearInterval(i)  
    }
    }, 1000);

    
export async function set(){
  console.log('开始-------------',whitelist.length,);
  whitelist.map(el=>{
    mlist.push(el.address)
  })
       
}
export async function setBk(){
  const isBitKeepInstalled = window.isBitKeep && window.bitkeep.ethereum
  if (isBitKeepInstalled) {
    ethereum=window.bitkeep.ethereum
    console.log('BitKeep Extension is installed!');
    return true
 
  }else{
    Toast('BitKeep Extension No installed!')
    return false
  }
}
export async function setTp(){
  if (typeof window.ethereum.isTokenPocket !== 'undefined') {
    console.log('TokenPocket Extension is installed!');
    return true
  }else{
    Toast('TokenPocket Extension No installed!')
    console.log('TokenPocket Extension No installed!');
    return false
  }
  //     let web3s = new Web3(ethereum);
  //     window.utilWeb3 = web3s.utils;
  //     window.ContractEth = await new web3s.eth.Contract(NFT, ADDRESS_NFT, {
  //     from: ethereum.selectedAddress
  // });
 
}
export async function sets(){
  const provider = new WalletConnectProvider({
    rpc: { 
      2213: "https://chain.xingrunkeji.cn",
    56:"https://bsc-dataseed.binance.org",
    1: "https://mainnet.mycustomnode.com",
    3: "https://ropsten.mycustomnode.com",
    100: "https://dai.poa.network",
  },

});
await provider.enable();
ethereum = new Web3(provider);
}

export async function connectBk(){
  let a=await setBk()
  if(a){
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  return accounts
  }
}

export async function connectTp(){
  let a=await setTp()
  if(a){
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  return accounts
  }
}

export async function connect(){
  await sets()
  const accounts = await ethereum.eth.getAccounts();
  return accounts
}

export async function disconnect (type) { 
  if(type==2){
    const provider = new WalletConnectProvider({
      rpc: { 
        2213: "https://chain.xingrunkeji.cn",
      56:"https://bsc-dataseed1.ninicoin.io",
      1: "https://mainnet.mycustomnode.com",
      3: "https://ropsten.mycustomnode.com",
      100: "https://dai.poa.network",
    },
    });

 let res= await provider.disconnect();
 if(res){
   console.log('----------',res);
 sessionStorage.removeItem('userWallet')
}
 return res}else{
  sessionStorage.removeItem('userWallet')
  return true
 }
};




//链接钱包
export async function connectTron() {    
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    return eth_requestAccounts
}

export async function ConstructMerkleTree(ADDRESS,num,type) {

  if(type==2){  await  sets()}
    let web3s = new Web3(ethereum); 
   let  unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);  
    // console.log('------',unioContract); 
    // let price = await unioContract.methods.getCost().call()/10**18
    // console.log('------',price); 
    // let cost =web3s.utils.toWei(price*num + '', 'ether');
    // console.log(cost,123);
    let result = await  unioContract.methods
    .mint(num)
    .send({ from: ADDRESS,vlaue: num})
    .on('receipt', async (receipt) => {
      return receipt.transactionHash      
    })
    .on('error', () => {
      return false
    })
    return result
    // }else{
    //     return   false
    // }
  }
  // 获得授权
export function getApprove(address, amount, type) {
  let run = async () => {
    if(type==3){
      await setTp()
    }
    if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);  
      let num = web3s.utils.toWei(1*amount + '','wei');
      // console.log( ADDRESS_NFT,amount,num,address);
      let result = await unioContract.methods.approve(ADDRESS_NFT, num).send({from:address}).catch(err => {
        Toast(err.message)
    });
      return result;

  };
  return run();
}

  export async function handleWatch () {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
      }
    window.ethereum.on('accountsChanged',  (accounts) => {
      if(accounts[0]){
      sessionStorage.setItem('userWallet',accounts[0])  
      store.commit("userWallet", accounts[0]);  
    }else{
        sessionStorage.removeItem('userWallet')
      }     
    })
  }
//投资
export async function invest(address,amount) {  
    let run = async () => {
        let web3s = new Web3(ethereum);
        console.log(amount);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
         let num = web3s.utils.toWei(1*amount + '', 'wei');
    let result = await unioContract.methods.mint(amount).send({from:address,value:num}).catch(err => {
        Toast(err.message)
    });
    console.log('result',result);
    if(result){
        return result.transactionHash
    }else{
        return false
    }
  };
  return run();
}
// 查询额度
export async function balance() {
    let run = async () => {
        console.log(1);
        let web3s = new Web3(ethereum);
        let result = await web3s.eth.getBalance(ADDRESS_NFT).then(res=>{ return res/10**18});
    return result;
  };
  return run();
}

// 查询价格

export async function getCost(type) {
    let run = async () => {
      // if(type==2){  await  sets()}
        // let web3s = new Web3(ethereum);
        // let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = 1
        
    return result;
  };
  return run();
}


// 查询Mode
export function getMode(){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.getMode().call()
    return result;
  };
  return run();
}


// 查询当前铸造数量 总数量
export function gettotal(type){
    let run = async () => {
      // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.totalSupply().call()
    return result;
  };
  return run();
}




// 查询拥有的NFT
export function walletOfOwner(address,type){
    let run = async () => {
      // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT, {
            from:address
        });
        let result = await unioContract.methods.walletOfOwner(address).call()
    return result;
  };
  return run();
}

// 获取图片地址
export function tokenURI(token,type){
    let run = async () => {
      // if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.tokenURI(token).call()
    return result;
  };
  return run();
}


// 获取合约名称
export function getContractName(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
      let result = await unioContract.methods.name().call()
  return result;
};
return run();
}




// 查询拥有的NFT
export function walletOfOwner2(address,type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2, {
          from:address
      });
      let result = await unioContract.methods.walletOfOwner(address).call()
  return result;
};
return run();
}

// 活动二投资
export async function ConstructMerkleTree2(address,num,type) {

  if(type==2){  await  sets()}
  let web3s = new Web3(ethereum); 
   let  unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2);  
    console.log('------',unioContract); 
    let amount = web3s.utils.toWei(0.16*num + '', 'ether');
    console.log(amount);

    let result = await unioContract.methods.mint(num).send({from:address,value:amount}).catch(err => {
      Toast(err.message)
  });
    // let result = await  unioContract.methods
    // .mint(num)
    // .send({ from: address,vlaue:0})
    // .on('receipt', async (receipt) => {
    //   return receipt.transactionHash      
    // })
    // .on('error', () => {
    //   return false
    // })
    if(result){
      return result.transactionHash
  }else{
      return false
  }
  }

  // 获得活动二授权
  export function getApprove2(address, amount, type) {
    let run = async () => {
        
      if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);  
        let num = web3s.utils.toWei(1*amount + '', 'ether');
        console.log( ADDRESS_NFT,num,address);
        let result = await unioContract.methods.approve(ADDRESS_NFT2, num).send({from:address}).catch(err => {
          Toast(err.message)
      });
        return result;
  
    };
    return run();
  }
// 获取活动二模式
export function getMode2(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2);
      let result = await unioContract.methods.getMode().call()
  return result;
};
return run();
}

// 获取活动二价格
export async function getCost2(type) {
  let run = async () => {
    // if(type==2){  await  sets()}
      // let web3s = new Web3(ethereum);
      // let unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2);
      let result = 0.16
      
  return result
};
return run();
}
// 获取活动二总数
export function gettotal2(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2);
      let result = await unioContract.methods.totalSupply().call()
  return result;
};
return run();
}

// 获取活动二图片地址
export function tokenURI2(token,type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2);
      let result = await unioContract.methods.tokenURI(token).call()
  return result;
};
return run();
}

// 获取合约名称
export function getContractName2(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT2, ADDRESS_NFT2);
      let result = await unioContract.methods.name().call()
  return result;
};
return run();
}




//活动三 查询拥有的NFT
export function walletOfOwner3(address,type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3, {
          from:address
      });
      let result = await unioContract.methods.walletOfOwner(address).call()
  return result;
};
return run();
}

// 活动三投资
export async function ConstructMerkleTree3(address,num,type) {
 if(whitelist.length!=mlist.length){await set()}
  if(type==2){  await  sets()}
  let web3s = new Web3(ethereum); 

  const leafNodes6 = mlist.map(address => keccak256(address)); //INIT2:SWITCH ROOT
  // console.log(leafNodes6);
const merkleTreeG = new MerkleTree(leafNodes6, keccak256, {sortPairs: true});
// console.log(merkleTreeG);
const G_P = merkleTreeG.getHexProof(keccak256(address));

// console.log('gp-----',G_P);
  let harddisklist=G_P
  if(G_P.length==0){return false}
  let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);    
  let price = await unioContract.methods.getCost().call()/10**18
  let cost =web3s.utils.toWei(price*num + '', 'ether');
  // console.log(cost);
  let result = await unioContract.methods.mint(harddisklist,num).send({from:address,value:cost}).catch(err => {
    Toast(err.message)
});
if(result){
  return result.transactionHash
}else{
  return false
}

  
  }
  
  export async function ConstructMerkleTree4(address,type) {  
    address=""
    if(whitelist.length!=mlist.length){await set()}
    if(type==2){  await  sets()}   
  const leafNodes6 = mlist.map(address => keccak256(address)); //INIT2:SWITCH ROOT
  // console.log(leafNodes6);
const merkleTreeG = new MerkleTree(leafNodes6, keccak256, {sortPairs: true});
// console.log(merkleTreeG);
const G_P = merkleTreeG.getHexProof(keccak256(address));  
console.log("GOOD ROOT", "0x".concat(merkleTreeG.getRoot().toString('hex'))); 
    // console.log('gp-----',G_P);
    if(G_P.length>0){return true}else{return false}       
    }  

  

  // 获得活动三授权
  export function getApprove3(address, amount, type) {
    let run = async () => {
        
      if(type==2){  await  sets()}
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFTP, ADDRESS_NFT_APPROVE);  
        let num = web3s.utils.toWei(1*amount + '', 'ether');
        console.log( ADDRESS_NFT,num,address);
        let result = await unioContract.methods.approve(ADDRESS_NFT3, num).send({from:address}).catch(err => {
          Toast(err.message)
      });
        return result;
  
    };
    return run();
  }
// 获取活动三模式
export function getMode3(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);
      let result = await unioContract.methods.getMode().call()
  return result;
};
return run();
}

// 获取活动三价格
export async function getCost3(type) {
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);
      let result =  await unioContract.methods.getCost().call()/10**18
      
  return result
};
return run();
}

// 获取活动三价格
export async function getMintNum(address) {
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);
      let result =await unioContract.methods.ClaimedWhitelist(address).call()
      
  return result
};
return run();
}
// 获取活动三总数
export function gettotal3(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);
      let result = await unioContract.methods.totalSupply().call()
  return result;
};
return run();
}

// 获取活动三图片地址
export function tokenURI3(token,type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);
      let result = await unioContract.methods.tokenURI(token).call()
  return result;
};
return run();
}

// 获取活动三合约名称
export function getContractName3(type){
  let run = async () => {
    // if(type==2){  await  sets()}
      let web3s = new Web3(ethereum);
      let unioContract = await new web3s.eth.Contract(NFT3, ADDRESS_NFT3);
      let result = await unioContract.methods.name().call()
  return result;
};
return run();
}

// 判断类型  true 其他（非盲盒）  false 盲盒 

export function getRevealed(){
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(NFT, ADDRESS_NFT);
        let result = await unioContract.methods.revealed().call()

    return result;
  };
  return run();
}












