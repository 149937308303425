<template>
  <div class="main">
    <mtop @handleFiexdToolItem="handleFiexdToolItem" />
    <div class="" :class="show ? 'h5' : ''">
      <div class="sheader" id="mint">
        <div class="box">
          <div class="left">
            <div class="banner-title">
              FIST NFT
            </div>
            <div class="banner-title">
              {{ $t('m.zly') }}
            </div>
            <div class="banner-title">
              {{ $t('m.bcy') }}
            </div>
            <div class="txt">{{ $t('m.bdpt') }}</div>
            <div class="">{{ $t('m.wfhfn') }}</div>
          </div>
          <img src="../assets/img/mf.png" alt="" class="bannner" />
        </div>
      </div>
      <div class="white">
        <div class="h3">{{ $t('m.wsmxzwm') }}</div>
        <div class="card">
          <div class="box">
            <div class="intro">
              <img
                src="../assets/img/intro01.png"
                alt=""
                class="intro-img m1"
              />
              <div>
                <p class="intro-tit">{{ $t('m.title1') }}</p>
                <p class="intro-txt">
                  {{ $t('m.info1') }}
                </p>
              </div>
            </div>
            <div class="intro">
              <div>
                <p class="intro-tit tr">{{ $t('m.title2') }}</p>
                <p class="intro-txt tr">
                  {{ $t('m.info2') }}
                </p>
              </div>
              <img
                src="../assets/img/intro02.png"
                alt=""
                class="intro-img m2"
              />
            </div>
            <div class="intro">
              <img
                src="../assets/img/intro03.png"
                alt=""
                class="intro-img m3"
              />
              <div>
                <p class="intro-tit">{{ $t('m.title3') }}</p>
                <p class="intro-txt">
                  {{ $t('m.info3') }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="left fadeInLeft">
            <img src="../assets/img/nft.png" alt="" class="img1" />
          </div> -->
          <!-- <div class="cont fadeInRight">
          -->
          <!-- <div class="right fadeInRight">
            <img src="../assets/img/whales.png" alt="" class="img2" />
          </div> -->
        </div>
      </div>

      <!-- 平台理念 -->
      <div class="our-idea">
        <div class="h3">{{ $t('m.ptln') }}</div>
        <div class="card">
          <div class="cont fadeInRight">
            <div class="box">
              <div class="tt1">
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/ec.png')
                      : require('../assets/img/ic.png')
                  "
                  alt=""
                  class="img1"
                  v-if="show"
                />
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/intro14.png')
                      : require('../assets/img/intro04.png')
                  "
                  alt=""
                  class="img1"
                  v-else
                />
              </div>
              <div class="tt2">
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/ec1.png')
                      : require('../assets/img/ic1.png')
                  "
                  alt=""
                  class="img1"
                  v-if="show"
                />
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/intro15.png')
                      : require('../assets/img/intro05.png')
                  "
                  alt=""
                  class="img1"
                  v-else
                />
              </div>
            </div>
            <div class="box">
              <div class="tt1">
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/ec2.png')
                      : require('../assets/img/ic2.png')
                  "
                  alt=""
                  class="img1"
                  v-if="show"
                />
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/intro16.png')
                      : require('../assets/img/intro06.png')
                  "
                  alt=""
                  class="img1"
                  v-else
                />
              </div>
              <div class="tt3">
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/ec3.png')
                      : require('../assets/img/ic3.png')
                  "
                  alt=""
                  class="img1"
                  v-if="show"
                />
                <img
                  :src="
                    dqyy == 'en-US'
                      ? require('../assets/img/intro17.png')
                      : require('../assets/img/intro07.png')
                  "
                  alt=""
                  class="img1"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 开始探索吧 -->
      <div class="begin">
        <div class="h3">{{ $t('m.kstsb') }}</div>
        <div class="card">
          <div class="box">
            <div class="intro">
              <div class="intro-btn">
                <img src="../assets/img/icon01.png" alt="" class="ic1" />
                <span>Ethereum {{ $t('m.sc') }}</span>
              </div>
            </div>
            <div class="intro">
              <div class="intro-btn" style="background: #fed702">
                <img src="../assets/img/icon02.png" alt="" />
                <span style="color: #000000">BNB Chain {{ $t('m.sc') }}</span>
              </div>
            </div>
            <div class="intro">
              <div class="intro-btn" style="background: #7840df">
                <img src="../assets/img/icon03.png" alt="" />
                <span>Polygon {{ $t('m.sc') }}</span>
              </div>
            </div>
            <div class="intro">
              <div class="intro-btn" style="background: #1b92ee">
                <img src="../assets/img/icon04.png" alt="" />
                <span>Solana {{ $t('m.sc') }}</span>
              </div>
            </div>
            <div class="intro">
              <div class="intro-btn" style="background: #ea3a3d">
                <img src="../assets/img/icon05.png" alt="" />
                <span>Avalanche {{ $t('m.sc') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 生态伙伴 -->
      <div class="ecological-partner begin">
        <div class="h3">{{ $t('m.sthb') }}</div>
        <div class="card">
          <van-image :src="require('../assets/img/home/' +item +'.png')"  fit="contain" class="ecological-partner-item"  v-for="item in 26" :key="item"/>
     
          <!-- <img
            class="ecological-partner-item"
            v-for="item in 13"
            :key="item"
            :src="require('../assets/img/home/' +item +'.png')"
          /> -->
        </div>
      </div>
    </div>

    <mbottom />
  </div>
</template>

<script>
import mtop from '../components/top.vue'
import mbottom from '../components/bottom.vue'
export default {
  data() {
    return {
      show: false,
      timer: false,
      screenWidth: document.body.clientWidth,
      sqloading: false,
      buynum: 1,
      Cbuy: true,
      Mintshow: false,
      Cmint: true,
      list: {},
      mode: 1,
      mlist: [],
      price: 0,
      Maxnum: 0,
      Max: 1,
      mp4: true,
    }
  },
  components: {
    mtop,
    mbottom,
  },
  computed: {
    userWallet() {
      if (this.$store.getters.userWallet) {
        return this.$store.getters.userWallet.replace(
          /(\w{8})\w+(\w{8})/,
          '$1...$2'
        )
      } else {
        return ''
      }
    },
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language
      } else {
        return 'zh-CN'
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true
    } else {
      this.show = this.$store.getters.phone
    }
  },
  created() {},
  watch: {
    '$store.getters.phone': function(val) {
      this.show = val
    },
    '$store.getters.sid': function(val) {
      this.handleFiexdToolItem(val)
    },
    '$store.getters.userWallet': function() {
      this.getModer()
    },

    deep: true,
    immediate: true,
  },
  methods: {
    tourl(e) {
      window.open(e)
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      })
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },

    getModer() {},

    down() {
      window.open(
        'https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk'
      )
    },
    no() {
      this.$notify(this.$i18n.t('m.jqqd'))
    },
    topage(e) {
      this.$router.replace({ path: e })
    },
    toCread() {},
    up() {
      if (this.sqloading || !this.Cmint) {
        return
      }
      this.buynum = this.buynum <= this.Max ? this.Max : Number(this.buynum) + 1
    },
    dow() {
      if (this.sqloading || !this.Cmint) {
        return
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1
    },
  },
}
</script>

<style lang="scss" scoped>
$t-f: 'DINPro';
$t-sf: 'MiSans';
.main {
  background: #ffffff;
  .sheader {
    background: url('../assets/img/tbg.png') no-repeat top center;
    background-size: cover;
    background-position: center;
    min-height: 860px;
    width: 100%;
    padding-top: 70px;
    .box {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      min-height: 860px;
    }
    .bannner {
      width: 620px;
      height: 650px;
    }
    .left {
      margin-top: -56px;
      font-size: 18px;
      font-family: $t-sf;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      text-align: left;
    }
    .txt {
      margin: 40px 0 20px;
    }
    .banner-title {
      text-align: left;
      font-size: 45px;
      font-family: $t-sf;
      font-weight: bold;
      color: #ffffff;
      line-height: 65px;
      letter-spacing: 8px;
    }
    .herder {
      width: 1200px;
      // margin: 71px auto 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .tt1 {
        font-size: 30px;
        font-family: $t-f;
        font-weight: bold;
        color: #ffffff;
        line-height: 38px;
        text-align: center;
      }
      .tt2 {
        margin-top: 6px;
        font-size: 30px;
        font-family: $t-f;
        font-weight: bold;
        color: #78bdfa;
        line-height: 38px;
      }
      .box {
        // display: flex;
        // align-items: center;
        // margin-top: 29px;
        // background: url("../assets/img/minbg.png") no-repeat;
        // background-size: 100% 100%;
        // width: calc(984px - 220px);
        // height: 195px;
        // padding-left: 110px;
        // padding-right: 110px;
        .stip {
          font-size: 24px;
          font-family: $t-f;
          font-weight: bold;
          color: #000000;
          line-height: 31px;
        }
        .creadw {
          margin-left: 143px;
          .btn {
            display: flex;
            white-space: nowrap;
            min-width: 136px;
            height: 52px;
            padding: 0 20px;
            background: #4c4cf3;
            border-radius: 7px;
            font-size: 16px;
            font-weight: 600;
            color: #ffffff;
            line-height: 52px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border: none;
          }
        }
        .mint {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 32px;
          .input {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 50px;
          }
          .cont {
            height: 50px;
            flex: 1;
            line-height: 50px;
            font-size: 30px;
          }
          .sinput {
            background: #ffffff;
            display: flex;
            align-items: center;
            height: 52px;
            padding: 0 20px;
          }
          .ixc {
            height: 24px;
            width: 14px;
            margin-left: 5px;
          }
          .stxt {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            line-height: 50px;
            text-align: center;
            white-space: nowrap;
            span {
              margin: 0 5px;
            }
          }
          .up {
            width: 51px;
            height: 52px;
            background: #4c4cf3;
            border-radius: 0px 8px 8px 0px;
            font-size: 30px;
            font-family: $t-f;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
          }
          .dow {
            width: 51px;
            height: 52px;
            background: #4c4cf3;
            border-radius: 8px 0px 0px 8px;
            font-size: 30px;
            font-family: $t-f;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
          }
        }
        .btn {
          display: flex;
          white-space: nowrap;
          min-width: 130px;
          height: 52px;
          border-radius: 8px;
          padding: 0 20px;
          background: #4c4cf3;
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          border: none;
        }
      }
      .tt3 {
        font-size: 26px;
        font-family: $t-f;
        font-weight: bold;
        color: #ffffff;
        line-height: 33px;
        margin: 15px auto 85px;
      }
    }
    .right {
      width: 634px;
      height: 541px;
      position: relative;
      img {
        width: 634px;
        height: 541px;
      }
    }
  }
  .sbtn {
    background: #171719;
    // background: #000000;
    height: 53px;
    width: 100%;
  }
  .gray {
    background: #c8c8c8;
    padding: 50px 0;
    min-height: 746px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tt4 {
      font-size: 30px;
      font-family: $t-f;
      font-weight: 600;
      color: #000000;
      line-height: 42px;
    }
    .img2 {
      // width: 100%;
    }
  }
  // 为什么选择我们
  .white {
    // min-height: calc(100vh - 70px);
    padding: 84px 0;
    // display: flex;
    // align-items: center;
    .h3 {
      // width: 210px;
      // height: 42px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #192638;
      line-height: 42px;
      margin: 0 auto 84px;
    }
    .box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 1200px;
      flex-direction: column;
      .intro {
        width: 100%;
        // margin-right: 80px;
        align-items: center;
        justify-content: space-between;
        display: flex;
        .intro-img {
          width: 173px;
          height: 200px;
          margin-bottom: 36px;
        }
        .m1 {
          width: 348px;
          height: 348px;
        }
        .m2 {
          width: 418px;
          height: 331px;
        }
        .m3 {
          width: 356px;
          height: 337px;
        }
        .intro-tit {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #192638;
          text-align: left;
        }
        .intro-txt {
          width: 600px;
          // height: 75px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #556370;
          line-height: 28px;
          text-align: left;
        }
        .tr {
          text-align: right;
        }
      }
      .intro:last-child {
        margin: 0;
      }
    }
  }

  // 我们的理念
  .our-idea {
    // min-height: calc(100vh - 70px);
    background: #f7fbff;
    padding: 90px 0;
    .h3 {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #192638;
      line-height: 42px;
      margin: 0 auto 82px;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1200px;
      .intro {
        // width: 372px;
        // margin-right: 250px;
        justify-content: center;
        .intro-img {
          width: 90px;
          height: 90px;
          margin-bottom: 30px;
        }
        .intro-tit {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #192638;
        }
      }
      .intro:last-child {
        margin: 0;
      }
    }
  }

  // 开始探索吧
  .begin {
    background: #ffffff;
    padding: 88px 0 110px;
    .h3 {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #192638;
      line-height: 42px;
      margin: 0 auto 82px;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .intro {
        // width: 372px;
        margin-right: 32px;
        justify-content: center;
        .intro-btn {
          width: 204px;
          height: 48px;
          background: #3128bb;
          border-radius: 8px;
          // padding: 14px 0 14px 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 18px;
            // height: 22px;
            margin-right: 12px;
          }
          // .ic1{
          //   width: 40px;
          // }
          span {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
      .intro:last-child {
        margin: 0;
      }
    }
  }

  .ecological-partner {
    padding-top: 0;

    .card {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }

    &-item {
      width: 260px;
      background: rgba(0,0,0,.2);
      height: 65px;
      margin: 20px 10px;
      border-radius: 8px;
      padding: 10px;
    }
  }
  .card {
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }
  .left {
    .img1 {
      width: 174px;
      height: 103px;
    }
  }
  .cont {
    min-width: 786px;
    // margin-left: 73px;
    .box {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin: 27px;
    }
    .tt1 {
      margin: 0 13px;
    }
    .tt2 {
      margin-top: -78px;
    }
    .tt3 {
      margin-top: -86px;
      margin-left: 25px;
    }

    .img1 {
      width: 576px;
      height: 294px;
    }
    .img2 {
      width: 542px;
      height: 255px;
    }
    .img3 {
      width: 426px;
      height: 258px;
    }
    .img4 {
      width: 408px;
      height: 208px;
    }
  }

  // .right {
  //   .img2 {
  //     width: 382px;
  //     height: 103px;
  //   }
  // }
  .hzhb {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tt {
      font-size: 38px;
      font-family: $t-f;
      font-weight: bold;
    }
    img {
      width: 1112px;
      height: 351px;
      margin: 71px auto;
    }
  }
  .popup {
    // width: 1200px;
    background: #171719;
    border: 6px solid #78c2fa;
    border-radius: 16px;
    .title {
      height: 64px;
      font-size: 28px;
      font-style: italic;
      font-weight: 600;
      color: #ffffff;
      line-height: 64px;
    }
    .boxs {
      padding: 20px 19px 30px;
      // height: 600px;
      overflow: auto;
      display: flex;
      flex-flow: wrap;

      .item {
        height: 270px;
        width: 270px;
        margin: 10px;
        position: relative;
      }
      .sbg {
        height: 264px;
        width: 264px;
        border-radius: 10px;
        cursor: pointer;
      }

      .mbg {
        height: 264px;
        width: 264px;
        border-radius: 20px;
        border: 1px solid #a5a6a7;
        display: flex;
        align-items: center;
        justify-content: center;
        .img {
          height: 250px;
          width: 250px;
          border-radius: 20px;
        }
      }
      .zzz {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        font-weight: 500;
        font-family: $t-f;
        color: #000000;
        line-height: 34px;
        white-space: nowrap;
      }
      .bh {
        min-width: 100px;
        font-size: 26px;
        font-family: $t-f;
        margin-top: 10px;
        font-weight: 600;
        color: #ffffff;
        white-space: nowrap;
      }
    }
    .bottom {
      // background: #ffffff;
      height: 131px;
      .btn {
        width: 235px;
        height: 56px;
        background: #4c4cf3;
        border-radius: 8px;
        box-shadow: 0px 10px 18px 0px rgb(23 23 25);
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        line-height: 62px;
        margin-top: 38px;
        cursor: pointer;
        border: none;
      }
    }
  }
  .popuph5 {
    width: calc(100vw - 100px);
    .boxs {
      justify-content: center;
    }
  }

  .h5 {
    .header {
      width: 100%;
      padding-top: 100px;
      background: linear-gradient(
        -65deg,
        #ebecff 0%,
        #f8f9fd 67%,
        #e8fafc 100%
      );
      .left {
        margin-top: 0;
        // background: url('../assets/imgh/stop.png') no-repeat;
        background-size: 253px 267px;
        height: 267px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 60px;
        .tt1 {
          font-size: 50px;
          font-family: $t-f;
          font-weight: bold;
          color: #4458fe;
        }
        .tt2 {
          font-size: 33px;
          font-family: $t-f;
          font-weight: bold;
          color: #333333;
        }
      }
      .right {
        img {
          width: 100%;
        }
      }
    }

    .cardh {
      .left {
        margin: 31px 61px;
        .tt1 {
          font-size: 42px;
          font-family: $t-f;
          font-weight: bold;
          color: #4458fe;
        }
        .tt2 {
          font-size: 42px;
          font-family: $t-f;
          font-weight: bold;
          color: #333333;
          margin-top: 17px;
          margin-bottom: 30px;
        }
        .tt3 {
          font-size: 25px;
          font-family: $t-f;
          font-weight: 400;
          color: #333333;
        }
      }
      .right {
        margin-top: 31px;
        img {
          width: 100%;
        }
      }
    }
    .cs {
      background: linear-gradient(38deg, #faf1ec 0%, #eeeef9 52%);
      margin-top: -34px;
      padding-top: 34px;
    }
    .shzhb {
      width: 100%;
      margin: 50px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .tt {
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
      }
      img {
        width: 698px;
        height: 219px;
        margin: 71px auto;
      }
    }
  }
  .h5 {
    .begin .box .intro .intro-btn {
      img {
        width: 28px;
        margin-right: 28px;
      }
      span {
        font-size: 28px;
      }
    }
    .sheader {
      background: url('../assets/img/hbg.png') no-repeat;
      background-size: cover;
      padding: 100px 0 226px;
      .box {
        flex-direction: column-reverse;
        width: 100%;

        .bannner {
          width: 610px;
          height: 640px;
          margin: 110px auto;
        }
        .left {
          font-size: 26px;
          font-family: MiSans-Normal, MiSans;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
          .txt {
            margin: 40px 0 20px;
          }
          .banner-title {
            font-size: 52px;
            font-family: $t-sf;
            font-weight: bold;
            color: #ffffff;
            line-height: 84px;
            letter-spacing: 8px;
            text-align: center;
          }
        }
      }
      .herder {
        width: 100%;
        .left {
          width: calc(100% - 40px);
          margin: 20px;
          .box {
            width: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 40px;
            .stip {
              font-size: 23px;
              margin-bottom: 10px;
              margin-right: 0;
            }
            .mint {
              margin-left: 0;
              .input {
                margin-right: 30px;
              }
            }
            .creadw {
              margin-left: 0;
            }
          }
        }
      }
    }
    .white {
      .box {
        .intro {
          // width: 372px;
          margin-right: 80px;
          justify-content: center;
          flex-direction: column-reverse;
          .intro-img {
            width: 446px;
            height: auto;
          }
          .intro-tit {
            font-size: 32px;
            font-weight: 600;
            color: #192638;
            text-align: left;
          }
          .intro-txt {
            font-size: 32px;
            font-weight: 400;
            color: #556370;
            line-height: 50px;
            width: 100%;
            margin: 0 auto;
            text-align: left;
          }
        }
        .intro:nth-child(2) {
          flex-direction: column;
        }
      }
    }

    .our-idea {
      .card .box {
        flex-direction: row !important;
        .img1 {
          width: 346px;
          height: 348px;
        }
        .img2 {
          width: 300px;
          height: 300px;
        }
        .img3 {
          width: 302px;
          height: 302px;
        }
        .img4 {
          width: 244px;
          height: 244px;
        }
      }
      .box {
        .intro {
          width: 50%;
          margin-right: 80px;
          justify-content: center;
          .intro-img {
            width: 114px;
            height: 114px;
          }
          .intro-tit {
            font-size: 24px;
            font-weight: 600;
            color: #192638;
          }
          .intro-txt {
            font-size: 32px;
            font-weight: 400;
            color: #556370;
            line-height: 50px;
            width: 80%;
            margin: 0 auto;
          }
        }
      }
    }

    .white,
    .our-idea,
    .begin {
      .h3 {
        font-size: 48px;
      }
      .card {
        flex-direction: column;
        width: 80%;
        .box {
          flex-direction: column;
          width: auto;
          .intro {
            margin: 0 0 40px;
          }
          .intro-btn {
            width: 408px;
            height: 96px;
            border-radius: 16px;
          }
        }

        .ecological-partner-item {
          // margin-bottom: 32px;
          margin: 10px 10px 22px;
          width: calc(100% - 40px ) !important;
          height: 100px !important;
        }
      }
    }
  }
}
</style>
