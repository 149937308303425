<template>
  <div class="main">
    <mtop @handleFiexdToolItem="handleFiexdToolItem" />
    <div class="" :class="show ? 'h5' : ''">
      <div class="white">
        <div class="card">
          <h2>{{$t('m.bjgrxx')}}</h2>
          <div class="info-container">
            <div class="info-title">{{$t('m.tx')}}  <span>({{$t('m.txzc')}})</span></div>
            <div class="info-con ">            
              <van-uploader
                :max-size="1000 * 1024"
                @oversize="onOversize"
                :after-read="afterRead"
              >
              <div class="upimg">
                <img src="../assets/img/upimg.png" alt="" srcset="" class="imgs">
                <div  class="uptxt">{{$t('m.scwj')}}</div>
              </div>                
              </van-uploader>

              <div class="head-img" v-if="form.avatar">   <img :src="form.avatar" alt="" ></div>
            </div>

          </div>
          <div class="info-container">
            <div class="info-title">{{$t('m.zyfmt')}}</div>
             
            <div class="info-con">
              <div class="cover-img" :style="bannerShow||form.banner?'backgroundImage:url('+form.banner+')':''">              
                <div class="tips" v-if="!bannerShow">             
                  <van-uploader
                    :max-size="3000 * 1024"
                    @oversize="onOversize"
                    :after-read="afterReadCover"
                  >
                     <div class="upimg">
                <img src="../assets/img/upimg.png" alt="" srcset="" class="imgs">
                <div  class="uptxt">{{$t('m.scwj')}}</div>
              </div>
                  </van-uploader>
                       <div class="txt">{{$t('m.zyfmtxx')}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="info-container">
            <div class="info-title">{{$t('m.yhm')}}</div>
            <div class="info-con">
              <input type="text" class="inp" v-model="form.username"  :placeholder="$t('m.yhmxx')"/>
            </div>
          </div>
          <div class="info-container">
            <div class="info-title">{{$t('m.jj')}}</div>
            <div class="info-con">
              <textarea class="txta" v-model="form.description" placeholder="请输入简介内容"></textarea>
            </div>
            <!-- <p class="tip">支持Markdown语法</p> -->
          </div>
          <div class="info-container">
            <div class="info-title">{{$t('m.tjlj')}}</div>
            <div class="info-con">
              <div class="link-container">
                <div class="link">
                  <div class="left">
                   {{$t('m.zylj')}}
                  </div>
                  <div class="right">
                    <input
                      type="text"
                      class="input"
                      :placeholder="$t('m.ndwz')"
                      v-model="form.website"
                    />
                  </div>
                </div>
                <div class="link">
                  <div class="left">
                   Twitter
                  </div>
                  <div class="right">
                    <input
                      type="text"
                      class="input"
                      placeholder="https://twitter.com/"
                      v-model="form.twitter"
                    />
                  </div>
                </div>
                <div class="link">
                  <div class="left">
                 {{$t('m.xlwb')}}
                  </div>
                  <div class="right">
                    <input
                      type="text"
                      class="input"
                      placeholder="https://weibo.com/"
                      v-model="form.weibo"
                    />
                  </div>
                </div>
                <div class="link">
                  <div class="left">
                  Instagram
                  </div>
                  <div class="right">
                    <input
                      type="text"
                      class="input"
                      placeholder="https://www.instagram.com/"
                      v-model="form.instagram"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sub-con">
            <van-button type="primary" class="sub" @click="onSubInfo()"
              >{{$t('m.tj')}}</van-button
            >
          </div>
        </div>
      </div>
    </div>

    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {
      show: false,
      timer: false,
      bannerShow: false,
      form: {
        avatar: require("@/assets/img/my-nft/user-icon.png"),
        banner: '', // 封面图
        username: '',
        description: '',
        website: '',
        twitter: '',
        weibo: '',
        instagram: '',
      },
    };
  },
  components: {
    mtop,
    mbottom,
  },
  computed: {
    // 用户地址
    userWallet() {
      if (this.$store.getters.userWallet) {
        return this.$store.getters.userWallet.replace(
          /(\w{8})\w+(\w{8})/,
          "$1...$2"
        );
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }

    this.getUserList();

    // console.log(new Date().getTime(), 'time')
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$store.getters.sid": function (val) {
      this.handleFiexdToolItem(val);
    },

    deep: true,
    immediate: true,
  },
  methods: {
    onOversize(file) {
      console.log(file);
      this.$notify(this.$i18n.t("m.scwjgd") );
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      let time = new Date().getTime();
      let str1 = "I0wgjYDj";
      let str2 = "$fwFq7QgPm5Gui7";
      let str3 = "jqged!%gO";

      let sha256 = this.$sha256(time + str1 + str2 + str3);

      let formData = new FormData();
      formData.append("files", file.file);

      let imgUrl = "http://www.fstnft.com/uploads/";

      this.$axios({
        method: "POST",
        url: `/api/upload?sign=${sha256}&t=${time}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          console.log(response, 77777777);
          this.$nextTick(() => {
            this.form.avatar = imgUrl + response.data.filename;
            // this.$notify.success("上传成功！");
          });
        })
        .catch(() => {
          this.$notify(this.$i18n.t("m.scsb"));
        });
    },
    afterReadCover(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      let time = new Date().getTime();
      let str1 = "I0wgjYDj";
      let str2 = "$fwFq7QgPm5Gui7";
      let str3 = "jqged!%gO";

      let sha256 = this.$sha256(time + str1 + str2 + str3);

      let formData = new FormData();
      formData.append("files", file.file);

      let imgUrl = "http://www.fstnft.com/uploads/";

      this.$axios({
        method: "POST",
        url: `/api/upload?sign=${sha256}&t=${time}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          console.log(response, 77777777);
          this.$nextTick(() => {
            this.form.banner = imgUrl + response.data.filename;

            this.bannerShow = true;
            // this.$notify.success("上传成功！");
          });
        })
        .catch(() => {
          this.$notify(this.$i18n.t("m.scsb"));
        });
    },
    onCloseImg() {
      this.bannerShow = false;
    },
    onSubInfo() {
      let time = new Date().getTime();
      let str1 = "I0wgjYDj";
      let str2 = "$fwFq7QgPm5Gui7";
      let str3 = "jqged!%gO";

      let sha256 = this.$sha256(time + str1 + str2 + str3);

      this.$axios({
        method: "PUT",
        url: `/api/accounts?sign=${sha256}&t=${time}`,
        data: this.form,
      })
        .then((response) => {
          if(response){
          this.$notify({message:this.$i18n.t("m.tjcg"),type:'success'});
          }
           this.$router.replace({ path: '/myNFT' });
          // this.getUserList();
        })
        .catch(() => {
          this.$notify(this.$i18n.t("m.tjsb"));
            
        });
    },
    // 获取用户资料
    getUserList() {
       this.$get({
            url: "accounts",
            data: { address: this.$store.getters.userWallet },
            callback: (res) => {
              this.$nextTick(function () {
                if (res.data) {
                  this.form = res.data[0];
                  // this.form.avatar='http://www.fstnft.com/uploads/cd6197b1d20ddc13f06d3e8e0.db83c7b2.png'
                  // this.form.banner="http://www.fstnft.com/uploads/cd6197b1d20ddc13f06d3e8e1.36b27ede.png"
                  console.log(this.form);
                } 
              });
            },
          });

   
    },
    tourl(e) {
      window.open(e);
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    down() {
      window.open(
        "https://xinrunkeji.oss-cn-beijing.aliyuncs.com/xingkewallet/xkwallet.apk"
      );
    },
    no() {
      this.$notify(this.$i18n.t("m.jqqd"));
    },
    topage(e) {
      this.$router.replace({ path: e });
    },
    toCread() {},
  },
};
</script>

<style lang="scss" scoped>
$t-f: "DINPro";
$t-sf:'MiSans';
.main {
  background:#F7F8FB;
  .mtop{
    background: #FFFFFF;
  }
  .mtop.h5{
    background: #101010 !important;
  }
  .white {
    padding: 80px 0;
    text-align: left;
    .card {
      width: 1200px;
      margin: 0 auto;
      h2 {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #192638;
        line-height: 50px;
        margin-bottom: 40px;
      }
      .info-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 40px;
      }
      .info-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #192638;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 18px;
        span{
          font-size: 14px;
font-family: $t-sf;
font-weight: 400;
color: #515151;
line-height: 19px;
        }
        
      }

      .info-con {
        display: flex;
        align-items: center;
        justify-content: flex-start;     
        
          margin-top: 20px;  
        .head-img {
          width: 110px;
          height: 110px;
          background: #d8d8d8;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 44px;
          overflow: hidden;
          margin: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt,p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #556370;
          line-height: 20px;
          width: 200px;
          margin-top: 10px;
        }
        .up {
          // width: 100px;
          // height: 47px;
          padding: 12px 18px;
          background: #fff5ce;
          border-radius: 26px;
          border: 1px solid #e5e5e5;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          cursor: pointer;
        }
     
        .cover-img {
          width: 540px;
height: 232px;
background: #FFFFFF;
border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 10px;
          position: relative;
          background-size: cover;
    background-repeat: no-repeat;
          .closeIcon {
            position: absolute;
            right: 20px;
            top: 10px;
            cursor: pointer;
          }
        }
        .inp {
          width: 688px;
          height: 50px;
              border: none;
    border-bottom: 1px solid #e6e8ec;
    margin-bottom: 0.13333rem;
    background: transparent;
        }
        .tip {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #556370;
        }
        .tips{
          display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffffd1;
    padding: 10px;
    border-radius: 10px;

        }
        .txta {
          width: 688px;
          height: 86px;
          padding: 15px;
          border: 1px solid #e6e8ec;
          background: transparent;
        }
      }
      .link-container {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        .link {
          width: 50%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          margin:25px 0;
          .left {
            height: 100%;
            align-items: center;
            display: flex;
            align-items: center;
            justify-content: center;
           font-size: 14px;
font-family: $t-sf;
font-weight: 500;
color: #000000;
line-height: 19px;
          }
          .right {
            width: 100%;
            .input {
              border: none;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #556370;
              padding: 20px 0;
              background: transparent;
              border-bottom: 1px solid #e6e8ec ;
            }
          }
        }
        .link:last-child {
          border: 0;
        }
      }
      .upimg{
        width: 94px;
height: 84px;
background: #FFFFFF;
border-radius: 2px;
         border: 1px dashed #979797;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         .imgs{
          width: 28px;
          height: 23px;
         }
         .uptxt{
          font-size: 12px;
font-family: $t-sf;
font-weight: 400;
color: #000000;
line-height: 16px;
margin-top: 11px;
         }
      }
      .sub {
        font-size: 18px;
font-family: $t-sf;
font-weight: 500;
color: #000000;
line-height: 24px;
     width: 540px;
height: 48px;
background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
border-radius: 6px;
        border: none;
        font-size: 28px;
        margin-top: 44px;
      }
    }
  }
}

.h5 {

  .white {
    .card {
      width: auto;
      margin: 100px 36px;
      .info-container {
        align-items: flex-start;
          .info-title{
           font-size: 32px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #192638;
line-height: 44px;
    
  }
      }
      .img{
        img{

        }
      }
      .info-con {
        flex-direction: column;
        align-items:flex-start;
        width: 100%;
        p{
          font-size: 28px;
font-weight: 400;
color: #556370;
line-height: 40px;
margin: 36px 0;
        }
        .up{
          min-width: 200px;
height: 94px;
background: #FFF5CE;
border-radius: 52px;
border: 2px solid #E5E5E5;
font-size: 32px;
        }
        .cover-img{
          width: 658px;
min-height: 456px;
        }
        .inp{
          width: 654px;
height: 86px;
padding: 0 20px;
        }
        .txta{
          width: 654px;
          padding: 20px;
        }
      }
      .link-container {
        flex-direction: column;
       height: 100%;
       width: 100%;
        .link{
          height: 108px;     
          width: 100%;  
          .left{
            font-size: 24px;
          }
          .right .input{
            font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #556370;
width: 100%;
          }
        }
      }
    .upimg{
  width: 188px;
height: 168px;
.imgs{
  width: 56px;
height: 46px;
}
.txt{
  margin-top: 10px;
  font-size: 20px;
font-family: $t-sf;
font-weight: 400;
color: #515151;
line-height: 26px;
}
    }
      .sub-con{

.sub{
  font-size: 32px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #222222;
line-height: 44px;
width: 690px;
height: 104px;
background: linear-gradient(138deg, #FFB300 0%, #FFFF00 100%);
border-radius: 24px;
}
      }
    }
  }
}
</style>