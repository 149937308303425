<template>
  <div class="main">
    <mtop @handleFiexdToolItem="handleFiexdToolItem" />

    <div class="empty">
      <van-empty description="敬请期待..." />
    </div>
    <mbottom />
  </div>
</template>
<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  components: {
    mtop,
    mbottom,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.main {
  .empty {
      padding-top: 40px;
      height: 80vh;
  }
}
</style>